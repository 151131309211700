/*!
 * msstatehighfive v1.0.3 (https://www.drupal.msstate.edu)
 * Author: ITS Web Development Team
 */
:root {
  --blue: #007da4;
  --indigo: #6610f2;
  --purple: #642f6c;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #a9431e;
  --yellow: #ffb81c;
  --green: #8f993e;
  --teal: #20c997;
  --cyan: #00aead;
  --white: #fff;
  --gray: #777777;
  --gray-dark: #333333;
  --sky: #bdd6e6;
  --lime: #b7bf10;
  --gold: #c99700;
  --taupe: #a69f88;
  --sand: #dac79d;
  --soil: #6e4c1e;
  --maroon: #5d1725;
  --maroon-light: #7a1e30;
  --black: #000;
  --primary: #5d1725;
  --secondary: #555555;
  --success: #8f993e;
  --info: #00aead;
  --warning: #ffb81c;
  --danger: #dc3545;
  --light: #f9f9f9;
  --dark: #333333;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.75rem; }

p {
  margin-top: 0;
  margin-bottom: 1.75rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #7a1e30;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #3c0f18;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #777777;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.75rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 3.157rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.4407rem + 2.2884vw) ; } }

h2, .h2 {
  font-size: 2.396rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.3646rem + 1.3752vw) ; } }

h3, .h3 {
  font-size: 1.777rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.3027rem + 0.6324vw) ; } }

h4, .h4 {
  font-size: 1.333rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.2583rem + 0.0996vw) ; } }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.75rem; }

.lead {
  font-size: 1.333rem;
  font-weight: 300; }
  @media (max-width: 1200px) {
    .lead {
      font-size: calc(1.2583rem + 0.0996vw) ; } }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 400;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #777777; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #777777; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #222222;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #222222; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-left: -30px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e1e1e1; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e1e1e1; }
  .table tbody + tbody {
    border-top: 2px solid #e1e1e1; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e1e1e1; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e1e1e1; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d2bec2; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ab868e; }

.table-hover .table-primary:hover {
  background-color: #c8afb4; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #c8afb4; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cfcfcf; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #a7a7a7; }

.table-hover .table-secondary:hover {
  background-color: #c2c2c2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c2c2c2; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e0e2c9; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #c5ca9b; }

.table-hover .table-success:hover {
  background-color: #d6d9b8; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d6d9b8; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e8e8; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7ad5d4; }

.table-hover .table-info:hover {
  background-color: #a5e2e2; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a5e2e2; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffebbf; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffda89; }

.table-hover .table-warning:hover {
  background-color: #ffe3a6; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe3a6; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfd; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfc; }

.table-hover .table-light:hover {
  background-color: #f0f0f0; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f0f0f0; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c6c6; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #959595; }

.table-hover .table-dark:hover {
  background-color: #b9b9b9; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9b9b9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #333333;
  border-color: #464646; }

.table .thead-light th {
  color: #555555;
  background-color: #eeeeee;
  border-color: #e1e1e1; }

.table-dark {
  color: #fff;
  background-color: #333333; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #464646; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.75em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: #222222;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #222222;
    background-color: #fff;
    border-color: #25cbff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #bbbbbb;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #bbbbbb;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #bbbbbb;
    opacity: 1; }
  .form-control::placeholder {
    color: #bbbbbb;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #eeeeee;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #222222;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.75; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.333rem;
  line-height: 1.75; }
  @media (max-width: 1200px) {
    .col-form-label-lg {
      font-size: calc(1.2583rem + 0.0996vw) ; } }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.75; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.75;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.75em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.75;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.75em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.333rem;
  line-height: 1.75;
  border-radius: 0.3rem; }
  @media (max-width: 1200px) {
    .form-control-lg {
      font-size: calc(1.2583rem + 0.0996vw) ; } }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1.75rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #777777; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #8f993e; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.75;
  color: #fff;
  background-color: rgba(143, 153, 62, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #8f993e;
  padding-right: calc(1.75em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%238f993e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.4375em + 0.1875rem);
  background-size: calc(0.875em + 0.375rem) calc(0.875em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #8f993e;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.75em + 0.75rem);
  background-position: top calc(0.4375em + 0.1875rem) right calc(0.4375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #8f993e;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23333333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%238f993e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.875em + 0.375rem) calc(0.875em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #8f993e;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #8f993e; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #8f993e; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #8f993e; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #adb951;
  background-color: #adb951; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #8f993e; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #8f993e; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #8f993e;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.75;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.75em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.4375em + 0.1875rem);
  background-size: calc(0.875em + 0.375rem) calc(0.875em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.75em + 0.75rem);
  background-position: top calc(0.4375em + 0.1875rem) right calc(0.4375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23333333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.875em + 0.375rem) calc(0.875em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #000;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.75;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .btn:hover {
    color: #000;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #5d1725;
  border-color: #5d1725; }
  .btn-primary:hover {
    color: #fff;
    background-color: #3e0f19;
    border-color: #340d15; }
  .btn-primary:focus, .btn-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #340d15;
    border-color: #2a0a11; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #555555;
  border-color: #555555; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #424242;
    border-color: #3c3c3c; }
  .btn-secondary:focus, .btn-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #555555;
    border-color: #555555; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #3c3c3c;
    border-color: #353535; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5); }

.btn-success {
  color: #fff;
  background-color: #8f993e;
  border-color: #8f993e; }
  .btn-success:hover {
    color: #fff;
    background-color: #767e33;
    border-color: #6d752f; }
  .btn-success:focus, .btn-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(160, 168, 91, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(160, 168, 91, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #8f993e;
    border-color: #8f993e; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #6d752f;
    border-color: #656c2c; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(160, 168, 91, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(160, 168, 91, 0.5); }

.btn-info {
  color: #fff;
  background-color: #00aead;
  border-color: #00aead; }
  .btn-info:hover {
    color: #fff;
    background-color: #008887;
    border-color: #007b7a; }
  .btn-info:focus, .btn-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 186, 185, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(38, 186, 185, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00aead;
    border-color: #00aead; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #007b7a;
    border-color: #006e6e; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 186, 185, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(38, 186, 185, 0.5); }

.btn-warning {
  color: #222222;
  background-color: #ffb81c;
  border-color: #ffb81c; }
  .btn-warning:hover {
    color: #222222;
    background-color: #f5a800;
    border-color: #e89f00; }
  .btn-warning:focus, .btn-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 162, 29, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(222, 162, 29, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #222222;
    background-color: #ffb81c;
    border-color: #ffb81c; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #222222;
    background-color: #e89f00;
    border-color: #db9700; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 162, 29, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(222, 162, 29, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #222222;
  background-color: #f9f9f9;
  border-color: #f9f9f9; }
  .btn-light:hover {
    color: #222222;
    background-color: #e6e6e6;
    border-color: #e0e0e0; }
  .btn-light:focus, .btn-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #222222;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #222222;
    background-color: #e0e0e0;
    border-color: #d9d9d9; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #333333;
  border-color: #333333; }
  .btn-dark:hover {
    color: #fff;
    background-color: #202020;
    border-color: #1a1a1a; }
  .btn-dark:focus, .btn-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1a1a;
    border-color: #131313; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }

.btn-outline-primary {
  color: #5d1725;
  border-color: #5d1725; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #5d1725;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5); }

.btn-outline-secondary {
  color: #555555;
  border-color: #555555; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #555555;
    border-color: #555555; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #555555;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #555555;
    border-color: #555555; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5); }

.btn-outline-success {
  color: #8f993e;
  border-color: #8f993e; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #8f993e;
    border-color: #8f993e; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #8f993e;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #8f993e;
    border-color: #8f993e; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.5); }

.btn-outline-info {
  color: #00aead;
  border-color: #00aead; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00aead;
    border-color: #00aead; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 174, 173, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 174, 173, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00aead;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00aead;
    border-color: #00aead; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 174, 173, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(0, 174, 173, 0.5); }

.btn-outline-warning {
  color: #ffb81c;
  border-color: #ffb81c; }
  .btn-outline-warning:hover {
    color: #222222;
    background-color: #ffb81c;
    border-color: #ffb81c; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffb81c;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #222222;
    background-color: #ffb81c;
    border-color: #ffb81c; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f9f9f9;
  border-color: #f9f9f9; }
  .btn-outline-light:hover {
    color: #222222;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f9f9f9;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #222222;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }

.btn-outline-dark {
  color: #333333;
  border-color: #333333; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #333333;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.btn-link {
  font-weight: 400;
  color: #7a1e30;
  text-decoration: none; }
  .btn-link:hover {
    color: #3c0f18;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #777777;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.333rem;
  line-height: 1.75;
  border-radius: 0.3rem; }
  @media (max-width: 1200px) {
    .btn-lg, .btn-group-lg > .btn {
      font-size: calc(1.2583rem + 0.0996vw) ; } }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.75;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #eeeeee; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #222222;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #151515;
    text-decoration: none;
    background-color: #f9f9f9; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #5d1725; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #777777;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #777777;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #222222; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: #222222;
  text-align: center;
  white-space: nowrap;
  background-color: #eeeeee;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.75em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.333rem;
  line-height: 1.75;
  border-radius: 0.3rem; }
  @media (max-width: 1200px) {
    .input-group-lg > .form-control,
    .input-group-lg > .custom-select,
    .input-group-lg > .input-group-prepend > .input-group-text,
    .input-group-lg > .input-group-append > .input-group-text,
    .input-group-lg > .input-group-prepend > .btn,
    .input-group-lg > .input-group-append > .btn {
      font-size: calc(1.2583rem + 0.0996vw) ; } }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.75em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.75;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.75rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #5d1725;
    background-color: #5d1725; }
  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #25cbff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #d4526c;
    border-color: #d4526c; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #777777; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #eeeeee; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.375rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #aaaaaa solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.375rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #5d1725;
  background-color: #5d1725; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(93, 23, 37, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(93, 23, 37, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(93, 23, 37, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.375rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #aaaaaa;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
    -ms-transform: translateX(0.75rem);
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(93, 23, 37, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.75em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: #222222;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23333333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .custom-select:focus {
    border-color: #25cbff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25); }
    .custom-select:focus::-ms-value {
      color: #222222;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #777777;
    background-color: #eeeeee; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.75em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.75em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.333rem; }
  @media (max-width: 1200px) {
    .custom-select-lg {
      font-size: calc(1.2583rem + 0.0996vw) ; } }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.75em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.75em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #25cbff;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #eeeeee; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.75em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.75;
  color: #222222;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.75em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.75;
    color: #222222;
    content: "Browse";
    background-color: #eeeeee;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 125, 164, 0.25);
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 125, 164, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 125, 164, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 125, 164, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #5d1725;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #d4526c; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e1e1e1;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #5d1725;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #d4526c; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e1e1e1;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #5d1725;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #d4526c; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #e1e1e1;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #e1e1e1;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #aaaaaa; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #aaaaaa; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #aaaaaa; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #777777;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #e1e1e1; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #eeeeee #eeeeee #e1e1e1; }
    .nav-tabs .nav-link.disabled {
      color: #777777;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #555555;
    background-color: #fff;
    border-color: #e1e1e1 #e1e1e1 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5d1725; }

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.20863rem;
  padding-bottom: 0.20863rem;
  margin-right: 1rem;
  font-size: 1.333rem;
  line-height: inherit;
  white-space: nowrap; }
  @media (max-width: 1200px) {
    .navbar-brand {
      font-size: calc(1.2583rem + 0.0996vw) ; } }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.333rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  @media (max-width: 1200px) {
    .navbar-toggler {
      font-size: calc(1.2583rem + 0.0996vw) ; } }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 30px; }
  @media (min-width: 576px) {
    .card-deck {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      margin-right: -30px;
      margin-left: -30px; }
      .card-deck .card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: 30px;
        margin-bottom: 0;
        margin-left: 30px; } }

.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 30px; }
  @media (min-width: 576px) {
    .card-group {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap; }
      .card-group > .card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1.75rem;
  list-style: none;
  background-color: #eeeeee;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #777777;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #777777; }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #7a1e30;
  background-color: #fff;
  border: 1px solid #e1e1e1; }
  .page-link:hover {
    z-index: 2;
    color: #3c0f18;
    text-decoration: none;
    background-color: #eeeeee;
    border-color: #e1e1e1; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #5d1725;
  border-color: #5d1725; }

.page-item.disabled .page-link {
  color: #777777;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e1e1e1; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.333rem;
  line-height: 1.75; }
  @media (max-width: 1200px) {
    .pagination-lg .page-link {
      font-size: calc(1.2583rem + 0.0996vw) ; } }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.75; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #5d1725; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #340d15; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #555555; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #3c3c3c; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5); }

.badge-success {
  color: #fff;
  background-color: #8f993e; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #6d752f; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.5); }

.badge-info {
  color: #fff;
  background-color: #00aead; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #007b7a; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 174, 173, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 174, 173, 0.5); }

.badge-warning {
  color: #222222;
  background-color: #ffb81c; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #222222;
    background-color: #e89f00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #222222;
  background-color: #f9f9f9; }
  a.badge-light:hover, a.badge-light:focus {
    color: #222222;
    background-color: #e0e0e0; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #333333; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1a1a1a; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eeeeee;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1.75rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #300c13;
  background-color: #dfd1d3;
  border-color: #d2bec2; }
  .alert-primary hr {
    border-top-color: #c8afb4; }
  .alert-primary .alert-link {
    color: #070203; }

.alert-secondary {
  color: #2c2c2c;
  background-color: #dddddd;
  border-color: #cfcfcf; }
  .alert-secondary hr {
    border-top-color: #c2c2c2; }
  .alert-secondary .alert-link {
    color: #131313; }

.alert-success {
  color: #4a5020;
  background-color: #e9ebd8;
  border-color: #e0e2c9; }
  .alert-success hr {
    border-top-color: #d6d9b8; }
  .alert-success .alert-link {
    color: #282c11; }

.alert-info {
  color: #005a5a;
  background-color: #ccefef;
  border-color: #b8e8e8; }
  .alert-info hr {
    border-top-color: #a5e2e2; }
  .alert-info .alert-link {
    color: #002727; }

.alert-warning {
  color: #85600f;
  background-color: #fff1d2;
  border-color: #ffebbf; }
  .alert-warning hr {
    border-top-color: #ffe3a6; }
  .alert-warning .alert-link {
    color: #573f0a; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818181;
  background-color: #fefefe;
  border-color: #fdfdfd; }
  .alert-light hr {
    border-top-color: #f0f0f0; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1b1b;
  background-color: #d6d6d6;
  border-color: #c6c6c6; }
  .alert-dark hr {
    border-top-color: #b9b9b9; }
  .alert-dark .alert-link {
    color: #020202; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #eeeeee;
  border-radius: 0.25rem; }

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #5d1725;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none; } }

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #555555;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #555555;
    text-decoration: none;
    background-color: #f9f9f9; }
  .list-group-item-action:active {
    color: #000;
    background-color: #eeeeee; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #777777;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #300c13;
  background-color: #d2bec2; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #300c13;
    background-color: #c8afb4; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #300c13;
    border-color: #300c13; }

.list-group-item-secondary {
  color: #2c2c2c;
  background-color: #cfcfcf; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #2c2c2c;
    background-color: #c2c2c2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #2c2c2c;
    border-color: #2c2c2c; }

.list-group-item-success {
  color: #4a5020;
  background-color: #e0e2c9; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #4a5020;
    background-color: #d6d9b8; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #4a5020;
    border-color: #4a5020; }

.list-group-item-info {
  color: #005a5a;
  background-color: #b8e8e8; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #005a5a;
    background-color: #a5e2e2; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #005a5a;
    border-color: #005a5a; }

.list-group-item-warning {
  color: #85600f;
  background-color: #ffebbf; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #85600f;
    background-color: #ffe3a6; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #85600f;
    border-color: #85600f; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818181;
  background-color: #fdfdfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818181;
    background-color: #f0f0f0; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818181;
    border-color: #818181; }

.list-group-item-dark {
  color: #1b1b1b;
  background-color: #c6c6c6; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1b1b;
    background-color: #b9b9b9; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1b1b;
    border-color: #1b1b1b; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #777777;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px);
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e1e1e1;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.75; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e1e1e1;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #000; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  -o-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  -o-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #5d1725 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #340d15 !important; }

.bg-secondary {
  background-color: #555555 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #3c3c3c !important; }

.bg-success {
  background-color: #8f993e !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #6d752f !important; }

.bg-info {
  background-color: #00aead !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #007b7a !important; }

.bg-warning {
  background-color: #ffb81c !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e89f00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f9f9f9 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e0e0e0 !important; }

.bg-dark {
  background-color: #333333 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1a1a1a !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e1e1e1 !important; }

.border-top {
  border-top: 1px solid #e1e1e1 !important; }

.border-right {
  border-right: 1px solid #e1e1e1 !important; }

.border-bottom {
  border-bottom: 1px solid #e1e1e1 !important; }

.border-left {
  border-left: 1px solid #e1e1e1 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #5d1725 !important; }

.border-secondary {
  border-color: #555555 !important; }

.border-success {
  border-color: #8f993e !important; }

.border-info {
  border-color: #00aead !important; }

.border-warning {
  border-color: #ffb81c !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f9f9f9 !important; }

.border-dark {
  border-color: #333333 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  -ms-grid-row-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  -ms-grid-row-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  -ms-grid-row-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.07) !important;
  box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.07) !important; }

.shadow {
  -webkit-box-shadow: 0 0.25rem 3rem rgba(0, 0, 0, 0.09) !important;
  box-shadow: 0 0.25rem 3rem rgba(0, 0, 0, 0.09) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 0.5rem 5rem rgba(0, 0, 0, 0.11) !important;
  box-shadow: 0 0.5rem 5rem rgba(0, 0, 0, 0.11) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-35 {
  width: 35% !important; }

.w-40 {
  width: 40% !important; }

.w-45 {
  width: 45% !important; }

.w-55 {
  width: 55% !important; }

.w-60 {
  width: 60% !important; }

.w-65 {
  width: 65% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-85 {
  width: 85% !important; }

.w-90 {
  width: 90% !important; }

.w-95 {
  width: 95% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-5 {
  height: 5% !important; }

.h-10 {
  height: 10% !important; }

.h-15 {
  height: 15% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-35 {
  height: 35% !important; }

.h-40 {
  height: 40% !important; }

.h-45 {
  height: 45% !important; }

.h-55 {
  height: 55% !important; }

.h-60 {
  height: 60% !important; }

.h-65 {
  height: 65% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-85 {
  height: 85% !important; }

.h-90 {
  height: 90% !important; }

.h-95 {
  height: 95% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 4.5rem !important; }

.mt-6,
.my-6 {
  margin-top: 4.5rem !important; }

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important; }

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important; }

.m-7 {
  margin: 6rem !important; }

.mt-7,
.my-7 {
  margin-top: 6rem !important; }

.mr-7,
.mx-7 {
  margin-right: 6rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 6rem !important; }

.ml-7,
.mx-7 {
  margin-left: 6rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 4.5rem !important; }

.pt-6,
.py-6 {
  padding-top: 4.5rem !important; }

.pr-6,
.px-6 {
  padding-right: 4.5rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important; }

.pl-6,
.px-6 {
  padding-left: 4.5rem !important; }

.p-7 {
  padding: 6rem !important; }

.pt-7,
.py-7 {
  padding-top: 6rem !important; }

.pr-7,
.px-7 {
  padding-right: 6rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 6rem !important; }

.pl-7,
.px-7 {
  padding-left: 6rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n6 {
  margin: -4.5rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -4.5rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -4.5rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -4.5rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -4.5rem !important; }

.m-n7 {
  margin: -6rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -6rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -6rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -6rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -6rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 4.5rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important; }
  .m-sm-7 {
    margin: 6rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 4.5rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important; }
  .p-sm-7 {
    padding: 6rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n6 {
    margin: -4.5rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4.5rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4.5rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4.5rem !important; }
  .m-sm-n7 {
    margin: -6rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -6rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -6rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -6rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -6rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 4.5rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important; }
  .m-md-7 {
    margin: 6rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 4.5rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important; }
  .p-md-7 {
    padding: 6rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n6 {
    margin: -4.5rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4.5rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4.5rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4.5rem !important; }
  .m-md-n7 {
    margin: -6rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -6rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -6rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -6rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -6rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 4.5rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important; }
  .m-lg-7 {
    margin: 6rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 4.5rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important; }
  .p-lg-7 {
    padding: 6rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n6 {
    margin: -4.5rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4.5rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4.5rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4.5rem !important; }
  .m-lg-n7 {
    margin: -6rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -6rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -6rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -6rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -6rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-6 {
    margin: 4.5rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important; }
  .m-xl-7 {
    margin: 6rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-6 {
    padding: 4.5rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important; }
  .p-xl-7 {
    padding: 6rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n6 {
    margin: -4.5rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4.5rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4.5rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4.5rem !important; }
  .m-xl-n7 {
    margin: -6rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -6rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -6rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -6rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -6rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #5d1725 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #20080d !important; }

.text-secondary {
  color: #555555 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #2f2f2f !important; }

.text-success {
  color: #8f993e !important; }

a.text-success:hover, a.text-success:focus {
  color: #5c6328 !important; }

.text-info {
  color: #00aead !important; }

a.text-info:hover, a.text-info:focus {
  color: #006261 !important; }

.text-warning {
  color: #ffb81c !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #cf8e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f9f9f9 !important; }

a.text-light:hover, a.text-light:focus {
  color: lightgray !important; }

.text-dark {
  color: #333333 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0d0d0d !important; }

.text-body {
  color: #000 !important; }

.text-muted {
  color: #777777 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #aaaaaa;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e1e1e1 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #e1e1e1; }
  .table .thead-dark th {
    color: inherit;
    border-color: #e1e1e1; } }

:root {
  --font-family-menu: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.msstatedrupal .toolbar .toolbar-bar,
.msstatedrupal .toolbar .toolbar-tray {
  z-index: 1250; }

.msstatedrupal .toolbar-oriented .toolbar-bar {
  z-index: 1002; }

.msstatedrupal .toolbar .toolbar-tray {
  z-index: 1002; }

.msstatedrupal .vertical-tabs {
  border-color: #e1e1e1; }

.msstatedrupal .vertical-tabs__menu {
  border-color: #e1e1e1; }

.msstatedrupal .vertical-tabs__menu-item {
  font-size: 0.875rem;
  border-color: #e1e1e1; }
  .msstatedrupal .vertical-tabs__menu-item a {
    padding: 1rem; }
    .msstatedrupal .vertical-tabs__menu-item a:hover, .msstatedrupal .vertical-tabs__menu-item a:focus {
      outline: 1px solid #aaaaaa; }

.msstatedrupal .vertical-tabs__panes {
  padding: 1rem; }

.msstatedrupal .views-exposed-form .form-inline:not(.form-row) label,
.msstatedrupal .views-exposed-form .form--inline:not(.form-row) label {
  margin: 0 .25rem 0 0; }

.msstatedrupal .views-exposed-form .form-inline:not(.form-row) .form-control,
.msstatedrupal .views-exposed-form .form--inline:not(.form-row) .form-control {
  margin: 0 .5rem 0 0; }

iframe {
  border: none; }

hr {
  margin-top: 4.193rem;
  margin-bottom: 4.193rem; }

img {
  max-width: 100%;
  height: auto; }

figcaption {
  font-size: 0.875rem;
  color: #555555;
  margin: .5rem 0; }

.hero p a:not(.btn),
.hero li a:not(.btn),
.hero table a:not(.btn),
.page p a:not(.btn),
.page li a:not(.btn),
.page table a:not(.btn) {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto; }

#skipnav-nav .skipnav-link {
  position: absolute;
  background: #fff;
  padding: 20px;
  z-index: 99999;
  -webkit-transform: translateY(-500%);
  -ms-transform: translateY(-500%);
  transform: translateY(-500%);
  -webkit-transition: -webkit-transform 1s linear;
  transition: -webkit-transform 1s linear;
  -o-transition: transform 1s linear;
  transition: transform 1s linear;
  transition: transform 1s linear, -webkit-transform 1s linear; }

#skipnav-nav .skipnav-link:focus {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear; }

.browsehappy {
  height: 28px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 28px;
  text-align: center; }
  .browsehappy a {
    color: inherit;
    text-decoration: underline; }

.container,
.container-fluid {
  max-width: 1200px; }
  .container.no-max-width,
  .container-fluid.no-max-width {
    max-width: none; }

.row-wrapper {
  height: auto;
  padding: 150px 0;
  position: relative; }
  .row-wrapper.padding-sm {
    padding: 100px 0; }
  .row-wrapper.padding-md {
    padding: 150px 0; }
  .row-wrapper.padding-lg {
    padding: 200px 0; }
  .row-wrapper .container-fluid {
    position: relative;
    z-index: 5; }

.page {
  position: relative;
  padding-top: 5.25rem;
  padding-bottom: 5.25rem; }

.page-category {
  padding-top: 0;
  padding-bottom: 0; }

h1 + h1, h2 + h1, h3 + h1, h4 + h1, h5 + h1, h6 + h1,
h1 + .h1, h2 + .h1, h3 + .h1, h4 + .h1, h5 + .h1, h6 + .h1,
.h1 + h1, .h2 + h1, .h3 + h1, .h4 + h1, .h5 + h1, .h6 + h1,
.h1 + .h1, .h2 + .h1, .h3 + .h1, .h4 + .h1, .h5 + .h1, .h6 + .h1,
p + h1, ul + h1, ol + h1, dl + h1, table + h1, form + h1, pre + h1, blockquote + h1, nav + h1, button + h1,
p + .h1, ul + .h1, ol + .h1, dl + .h1, table + .h1, form + .h1, pre + .h1, blockquote + .h1, nav + .h1, button + .h1,
.btn-group + h1, .carousel + h1, .collapse + h1, .dropdown + h1, .jumbotron + h1, .media + h1, .nav + h1, .pagination + h1, .progress + h1, .alert + h1, .card + h1, .list-group + h1,
.btn-group + .h1, .carousel + .h1, .collapse + .h1, .dropdown + .h1, .jumbotron + .h1, .media + .h1, .nav + .h1, .pagination + .h1, .progress + .h1, .alert + .h1, .card + .h1, .list-group + .h1 {
  margin-top: 5.52475rem; }

h1 + h2, h2 + h2, h3 + h2, h4 + h2, h5 + h2, h6 + h2,
h1 + .h2, h2 + .h2, h3 + .h2, h4 + .h2, h5 + .h2, h6 + .h2,
.h1 + h2, .h2 + h2, .h3 + h2, .h4 + h2, .h5 + h2, .h6 + h2,
.h1 + .h2, .h2 + .h2, .h3 + .h2, .h4 + .h2, .h5 + .h2, .h6 + .h2,
p + h2, ul + h2, ol + h2, dl + h2, table + h2, form + h2, pre + h2, blockquote + h2, nav + h2, button + h2,
p + .h2, ul + .h2, ol + .h2, dl + .h2, table + .h2, form + .h2, pre + .h2, blockquote + .h2, nav + .h2, button + .h2,
.btn-group + h2, .carousel + h2, .collapse + h2, .dropdown + h2, .jumbotron + h2, .media + h2, .nav + h2, .pagination + h2, .progress + h2, .alert + h2, .card + h2, .list-group + h2,
.btn-group + .h2, .carousel + .h2, .collapse + .h2, .dropdown + .h2, .jumbotron + .h2, .media + .h2, .nav + .h2, .pagination + .h2, .progress + .h2, .alert + .h2, .card + .h2, .list-group + .h2 {
  margin-top: 4.193rem; }

h1 + h3, h2 + h3, h3 + h3, h4 + h3, h5 + h3, h6 + h3,
h1 + .h3, h2 + .h3, h3 + .h3, h4 + .h3, h5 + .h3, h6 + .h3,
.h1 + h3, .h2 + h3, .h3 + h3, .h4 + h3, .h5 + h3, .h6 + h3,
.h1 + .h3, .h2 + .h3, .h3 + .h3, .h4 + .h3, .h5 + .h3, .h6 + .h3,
p + h3, ul + h3, ol + h3, dl + h3, table + h3, form + h3, pre + h3, blockquote + h3, nav + h3, button + h3,
p + .h3, ul + .h3, ol + .h3, dl + .h3, table + .h3, form + .h3, pre + .h3, blockquote + .h3, nav + .h3, button + .h3,
.btn-group + h3, .carousel + h3, .collapse + h3, .dropdown + h3, .jumbotron + h3, .media + h3, .nav + h3, .pagination + h3, .progress + h3, .alert + h3, .card + h3, .list-group + h3,
.btn-group + .h3, .carousel + .h3, .collapse + .h3, .dropdown + .h3, .jumbotron + .h3, .media + .h3, .nav + .h3, .pagination + .h3, .progress + .h3, .alert + .h3, .card + .h3, .list-group + .h3 {
  margin-top: 3.10975rem; }

h1 + h4, h2 + h4, h3 + h4, h4 + h4, h5 + h4, h6 + h4,
h1 + .h4, h2 + .h4, h3 + .h4, h4 + .h4, h5 + .h4, h6 + .h4,
.h1 + h4, .h2 + h4, .h3 + h4, .h4 + h4, .h5 + h4, .h6 + h4,
.h1 + .h4, .h2 + .h4, .h3 + .h4, .h4 + .h4, .h5 + .h4, .h6 + .h4,
p + h4, ul + h4, ol + h4, dl + h4, table + h4, form + h4, pre + h4, blockquote + h4, nav + h4, button + h4,
p + .h4, ul + .h4, ol + .h4, dl + .h4, table + .h4, form + .h4, pre + .h4, blockquote + .h4, nav + .h4, button + .h4,
.btn-group + h4, .carousel + h4, .collapse + h4, .dropdown + h4, .jumbotron + h4, .media + h4, .nav + h4, .pagination + h4, .progress + h4, .alert + h4, .card + h4, .list-group + h4,
.btn-group + .h4, .carousel + .h4, .collapse + .h4, .dropdown + .h4, .jumbotron + .h4, .media + .h4, .nav + .h4, .pagination + .h4, .progress + .h4, .alert + .h4, .card + .h4, .list-group + .h4 {
  margin-top: 2.33275rem; }

h1 + h5, h2 + h5, h3 + h5, h4 + h5, h5 + h5, h6 + h5,
h1 + .h5, h2 + .h5, h3 + .h5, h4 + .h5, h5 + .h5, h6 + .h5,
.h1 + h5, .h2 + h5, .h3 + h5, .h4 + h5, .h5 + h5, .h6 + h5,
.h1 + .h5, .h2 + .h5, .h3 + .h5, .h4 + .h5, .h5 + .h5, .h6 + .h5,
p + h5, ul + h5, ol + h5, dl + h5, table + h5, form + h5, pre + h5, blockquote + h5, nav + h5, button + h5,
p + .h5, ul + .h5, ol + .h5, dl + .h5, table + .h5, form + .h5, pre + .h5, blockquote + .h5, nav + .h5, button + .h5,
.btn-group + h5, .carousel + h5, .collapse + h5, .dropdown + h5, .jumbotron + h5, .media + h5, .nav + h5, .pagination + h5, .progress + h5, .alert + h5, .card + h5, .list-group + h5,
.btn-group + .h5, .carousel + .h5, .collapse + .h5, .dropdown + .h5, .jumbotron + .h5, .media + .h5, .nav + .h5, .pagination + .h5, .progress + .h5, .alert + .h5, .card + .h5, .list-group + .h5 {
  margin-top: 1.75rem; }

h1 + h6, h2 + h6, h3 + h6, h4 + h6, h5 + h6, h6 + h6,
h1 + .h6, h2 + .h6, h3 + .h6, h4 + .h6, h5 + .h6, h6 + .h6,
.h1 + h6, .h2 + h6, .h3 + h6, .h4 + h6, .h5 + h6, .h6 + h6,
.h1 + .h6, .h2 + .h6, .h3 + .h6, .h4 + .h6, .h5 + .h6, .h6 + .h6,
p + h6, ul + h6, ol + h6, dl + h6, table + h6, form + h6, pre + h6, blockquote + h6, nav + h6, button + h6,
p + .h6, ul + .h6, ol + .h6, dl + .h6, table + .h6, form + .h6, pre + .h6, blockquote + .h6, nav + .h6, button + .h6,
.btn-group + h6, .carousel + h6, .collapse + h6, .dropdown + h6, .jumbotron + h6, .media + h6, .nav + h6, .pagination + h6, .progress + h6, .alert + h6, .card + h6, .list-group + h6,
.btn-group + .h6, .carousel + .h6, .collapse + .h6, .dropdown + .h6, .jumbotron + .h6, .media + .h6, .nav + .h6, .pagination + .h6, .progress + .h6, .alert + .h6, .card + .h6, .list-group + .h6 {
  margin-top: 1.75rem; }

.first-lead > p:first-child {
  font-size: 1.333rem;
  font-weight: 300; }
  @media (max-width: 1200px) {
    .first-lead > p:first-child {
      font-size: calc(1.2583rem + 0.0996vw) ; } }

ol,
ul,
dl {
  margin-bottom: 1.75rem; }

p a:not(.btn) {
  text-decoration: underline; }

section ul:not(.nav) li a,
section ol:not(.nav) li a,
article ul:not(.nav) li a,
article ol:not(.nav) li a {
  text-decoration: underline; }

.text-white a:not(.btn):not(.card) {
  color: inherit; }

p > .btn {
  margin-bottom: 4px; }

blockquote, .blockquote {
  margin-bottom: 1.75rem; }

.sm-gutters {
  margin-right: -15px;
  margin-left: -15px; }
  .sm-gutters > .col,
  .sm-gutters > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px; }

.form-required::after {
  content: '';
  vertical-align: super;
  display: inline-block;
  background-image: url("//cdn01.its.msstate.edu/i/msstatehighfive/img/icons/required.svg");
  background-repeat: no-repeat;
  background-size: 6px 6px;
  width: 6px;
  height: 6px;
  margin: 0 0.3em; }

.form-surprise {
  display: none; }

.btn-maroon-primary {
  color: #fff;
  background-color: #5d1725;
  border-color: #5d1725; }
  .btn-maroon-primary:hover {
    color: #fff;
    background-color: #3e0f19;
    border-color: #340d15; }
  .btn-maroon-primary:focus, .btn-maroon-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5); }
  .btn-maroon-primary.disabled, .btn-maroon-primary:disabled {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
  .btn-maroon-primary:not(:disabled):not(.disabled):active, .btn-maroon-primary:not(:disabled):not(.disabled).active,
  .show > .btn-maroon-primary.dropdown-toggle {
    color: #fff;
    background-color: #340d15;
    border-color: #2a0a11; }
    .btn-maroon-primary:not(:disabled):not(.disabled):active:focus, .btn-maroon-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5); }

.btn-maroon-100 {
  color: #fff;
  background-color: #96253c;
  border-color: #96253c; }
  .btn-maroon-100:hover {
    color: #fff;
    background-color: #781e30;
    border-color: #6d1b2c; }
  .btn-maroon-100:focus, .btn-maroon-100.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(166, 70, 89, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(166, 70, 89, 0.5); }
  .btn-maroon-100.disabled, .btn-maroon-100:disabled {
    color: #fff;
    background-color: #96253c;
    border-color: #96253c; }
  .btn-maroon-100:not(:disabled):not(.disabled):active, .btn-maroon-100:not(:disabled):not(.disabled).active,
  .show > .btn-maroon-100.dropdown-toggle {
    color: #fff;
    background-color: #6d1b2c;
    border-color: #631927; }
    .btn-maroon-100:not(:disabled):not(.disabled):active:focus, .btn-maroon-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon-100.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(166, 70, 89, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(166, 70, 89, 0.5); }

.btn-maroon-200 {
  color: #fff;
  background-color: #882236;
  border-color: #882236; }
  .btn-maroon-200:hover {
    color: #fff;
    background-color: #691a2a;
    border-color: #5f1826; }
  .btn-maroon-200:focus, .btn-maroon-200.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(154, 67, 84, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(154, 67, 84, 0.5); }
  .btn-maroon-200.disabled, .btn-maroon-200:disabled {
    color: #fff;
    background-color: #882236;
    border-color: #882236; }
  .btn-maroon-200:not(:disabled):not(.disabled):active, .btn-maroon-200:not(:disabled):not(.disabled).active,
  .show > .btn-maroon-200.dropdown-toggle {
    color: #fff;
    background-color: #5f1826;
    border-color: #551522; }
    .btn-maroon-200:not(:disabled):not(.disabled):active:focus, .btn-maroon-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon-200.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(154, 67, 84, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(154, 67, 84, 0.5); }

.btn-maroon-300 {
  color: #fff;
  background-color: #7a1e30;
  border-color: #7a1e30; }
  .btn-maroon-300:hover {
    color: #fff;
    background-color: #5b1624;
    border-color: #511420; }
  .btn-maroon-300:focus, .btn-maroon-300.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(142, 64, 79, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(142, 64, 79, 0.5); }
  .btn-maroon-300.disabled, .btn-maroon-300:disabled {
    color: #fff;
    background-color: #7a1e30;
    border-color: #7a1e30; }
  .btn-maroon-300:not(:disabled):not(.disabled):active, .btn-maroon-300:not(:disabled):not(.disabled).active,
  .show > .btn-maroon-300.dropdown-toggle {
    color: #fff;
    background-color: #511420;
    border-color: #47111c; }
    .btn-maroon-300:not(:disabled):not(.disabled):active:focus, .btn-maroon-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon-300.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(142, 64, 79, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(142, 64, 79, 0.5); }

.btn-maroon-400 {
  color: #fff;
  background-color: #6b1b2b;
  border-color: #6b1b2b; }
  .btn-maroon-400:hover {
    color: #fff;
    background-color: #4d131e;
    border-color: #42101a; }
  .btn-maroon-400:focus, .btn-maroon-400.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(129, 61, 75, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(129, 61, 75, 0.5); }
  .btn-maroon-400.disabled, .btn-maroon-400:disabled {
    color: #fff;
    background-color: #6b1b2b;
    border-color: #6b1b2b; }
  .btn-maroon-400:not(:disabled):not(.disabled):active, .btn-maroon-400:not(:disabled):not(.disabled).active,
  .show > .btn-maroon-400.dropdown-toggle {
    color: #fff;
    background-color: #42101a;
    border-color: #380e16; }
    .btn-maroon-400:not(:disabled):not(.disabled):active:focus, .btn-maroon-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon-400.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(129, 61, 75, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(129, 61, 75, 0.5); }

.btn-maroon-500 {
  color: #fff;
  background-color: #5d1725;
  border-color: #5d1725; }
  .btn-maroon-500:hover {
    color: #fff;
    background-color: #3e0f19;
    border-color: #340d15; }
  .btn-maroon-500:focus, .btn-maroon-500.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5); }
  .btn-maroon-500.disabled, .btn-maroon-500:disabled {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
  .btn-maroon-500:not(:disabled):not(.disabled):active, .btn-maroon-500:not(:disabled):not(.disabled).active,
  .show > .btn-maroon-500.dropdown-toggle {
    color: #fff;
    background-color: #340d15;
    border-color: #2a0a11; }
    .btn-maroon-500:not(:disabled):not(.disabled):active:focus, .btn-maroon-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon-500.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5); }

.btn-maroon-600 {
  color: #fff;
  background-color: #4f131f;
  border-color: #4f131f; }
  .btn-maroon-600:hover {
    color: #fff;
    background-color: #300c13;
    border-color: #26090f; }
  .btn-maroon-600:focus, .btn-maroon-600.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 55, 65, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(105, 55, 65, 0.5); }
  .btn-maroon-600.disabled, .btn-maroon-600:disabled {
    color: #fff;
    background-color: #4f131f;
    border-color: #4f131f; }
  .btn-maroon-600:not(:disabled):not(.disabled):active, .btn-maroon-600:not(:disabled):not(.disabled).active,
  .show > .btn-maroon-600.dropdown-toggle {
    color: #fff;
    background-color: #26090f;
    border-color: #1c070b; }
    .btn-maroon-600:not(:disabled):not(.disabled):active:focus, .btn-maroon-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon-600.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 55, 65, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(105, 55, 65, 0.5); }

.btn-maroon-700 {
  color: #fff;
  background-color: #40101a;
  border-color: #40101a; }
  .btn-maroon-700:hover {
    color: #fff;
    background-color: #22080d;
    border-color: #170609; }
  .btn-maroon-700:focus, .btn-maroon-700.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(93, 52, 60, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(93, 52, 60, 0.5); }
  .btn-maroon-700.disabled, .btn-maroon-700:disabled {
    color: #fff;
    background-color: #40101a;
    border-color: #40101a; }
  .btn-maroon-700:not(:disabled):not(.disabled):active, .btn-maroon-700:not(:disabled):not(.disabled).active,
  .show > .btn-maroon-700.dropdown-toggle {
    color: #fff;
    background-color: #170609;
    border-color: #0d0305; }
    .btn-maroon-700:not(:disabled):not(.disabled):active:focus, .btn-maroon-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon-700.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(93, 52, 60, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(93, 52, 60, 0.5); }

.btn-maroon-800 {
  color: #fff;
  background-color: #320c14;
  border-color: #320c14; }
  .btn-maroon-800:hover {
    color: #fff;
    background-color: #130508;
    border-color: #090204; }
  .btn-maroon-800:focus, .btn-maroon-800.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(81, 49, 55, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(81, 49, 55, 0.5); }
  .btn-maroon-800.disabled, .btn-maroon-800:disabled {
    color: #fff;
    background-color: #320c14;
    border-color: #320c14; }
  .btn-maroon-800:not(:disabled):not(.disabled):active, .btn-maroon-800:not(:disabled):not(.disabled).active,
  .show > .btn-maroon-800.dropdown-toggle {
    color: #fff;
    background-color: #090204;
    border-color: black; }
    .btn-maroon-800:not(:disabled):not(.disabled):active:focus, .btn-maroon-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon-800.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(81, 49, 55, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(81, 49, 55, 0.5); }

.btn-maroon-900 {
  color: #fff;
  background-color: #24090e;
  border-color: #24090e; }
  .btn-maroon-900:hover {
    color: #fff;
    background-color: #050102;
    border-color: black; }
  .btn-maroon-900:focus, .btn-maroon-900.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(69, 46, 50, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(69, 46, 50, 0.5); }
  .btn-maroon-900.disabled, .btn-maroon-900:disabled {
    color: #fff;
    background-color: #24090e;
    border-color: #24090e; }
  .btn-maroon-900:not(:disabled):not(.disabled):active, .btn-maroon-900:not(:disabled):not(.disabled).active,
  .show > .btn-maroon-900.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-maroon-900:not(:disabled):not(.disabled):active:focus, .btn-maroon-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon-900.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(69, 46, 50, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(69, 46, 50, 0.5); }

.btn-gray-100 {
  color: #222222;
  background-color: #f9f9f9;
  border-color: #f9f9f9; }
  .btn-gray-100:hover {
    color: #222222;
    background-color: #e6e6e6;
    border-color: #e0e0e0; }
  .btn-gray-100:focus, .btn-gray-100.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5); }
  .btn-gray-100.disabled, .btn-gray-100:disabled {
    color: #222222;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
  .btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #222222;
    background-color: #e0e0e0;
    border-color: #d9d9d9; }
    .btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5); }

.btn-gray-200 {
  color: #222222;
  background-color: #eeeeee;
  border-color: #eeeeee; }
  .btn-gray-200:hover {
    color: #222222;
    background-color: #dbdbdb;
    border-color: #d5d5d5; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 207, 207, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(207, 207, 207, 0.5); }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    color: #222222;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #222222;
    background-color: #d5d5d5;
    border-color: #cecece; }
    .btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-200.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 207, 207, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(207, 207, 207, 0.5); }

.btn-gray-300 {
  color: #222222;
  background-color: #e1e1e1;
  border-color: #e1e1e1; }
  .btn-gray-300:hover {
    color: #222222;
    background-color: #cecece;
    border-color: #c8c8c8; }
  .btn-gray-300:focus, .btn-gray-300.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5); }
  .btn-gray-300.disabled, .btn-gray-300:disabled {
    color: #222222;
    background-color: #e1e1e1;
    border-color: #e1e1e1; }
  .btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-gray-300.dropdown-toggle {
    color: #222222;
    background-color: #c8c8c8;
    border-color: #c1c1c1; }
    .btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-300.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5); }

.btn-gray-400 {
  color: #222222;
  background-color: #cccccc;
  border-color: #cccccc; }
  .btn-gray-400:hover {
    color: #222222;
    background-color: #b9b9b9;
    border-color: #b3b3b3; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.5); }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    color: #222222;
    background-color: #cccccc;
    border-color: #cccccc; }
  .btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #222222;
    background-color: #b3b3b3;
    border-color: #acacac; }
    .btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-400.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(179, 179, 179, 0.5); }

.btn-gray-500 {
  color: #222222;
  background-color: #aaaaaa;
  border-color: #aaaaaa; }
  .btn-gray-500:hover {
    color: #222222;
    background-color: #979797;
    border-color: #919191; }
  .btn-gray-500:focus, .btn-gray-500.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5); }
  .btn-gray-500.disabled, .btn-gray-500:disabled {
    color: #222222;
    background-color: #aaaaaa;
    border-color: #aaaaaa; }
  .btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #919191;
    border-color: #8a8a8a; }
    .btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-500.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(150, 150, 150, 0.5); }

.btn-gray-600 {
  color: #fff;
  background-color: #777777;
  border-color: #777777; }
  .btn-gray-600:hover {
    color: #fff;
    background-color: #646464;
    border-color: #5e5e5e; }
  .btn-gray-600:focus, .btn-gray-600.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5); }
  .btn-gray-600.disabled, .btn-gray-600:disabled {
    color: #fff;
    background-color: #777777;
    border-color: #777777; }
  .btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #5e5e5e;
    border-color: #575757; }
    .btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-600.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5); }

.btn-gray-700 {
  color: #fff;
  background-color: #555555;
  border-color: #555555; }
  .btn-gray-700:hover {
    color: #fff;
    background-color: #424242;
    border-color: #3c3c3c; }
  .btn-gray-700:focus, .btn-gray-700.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5); }
  .btn-gray-700.disabled, .btn-gray-700:disabled {
    color: #fff;
    background-color: #555555;
    border-color: #555555; }
  .btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #3c3c3c;
    border-color: #353535; }
    .btn-gray-700:not(:disabled):not(.disabled):active:focus, .btn-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-700.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5); }

.btn-gray-800 {
  color: #fff;
  background-color: #333333;
  border-color: #333333; }
  .btn-gray-800:hover {
    color: #fff;
    background-color: #202020;
    border-color: #1a1a1a; }
  .btn-gray-800:focus, .btn-gray-800.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }
  .btn-gray-800.disabled, .btn-gray-800:disabled {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
  .btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #1a1a1a;
    border-color: #131313; }
    .btn-gray-800:not(:disabled):not(.disabled):active:focus, .btn-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-800.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }

.btn-gray-900 {
  color: #fff;
  background-color: #222222;
  border-color: #222222; }
  .btn-gray-900:hover {
    color: #fff;
    background-color: #0f0f0f;
    border-color: #090909; }
  .btn-gray-900:focus, .btn-gray-900.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }
  .btn-gray-900.disabled, .btn-gray-900:disabled {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }
  .btn-gray-900:not(:disabled):not(.disabled):active, .btn-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-gray-900.dropdown-toggle {
    color: #fff;
    background-color: #090909;
    border-color: #020202; }
    .btn-gray-900:not(:disabled):not(.disabled):active:focus, .btn-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-900.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5); }

.btn-blue {
  color: #fff;
  background-color: #007da4;
  border-color: #007da4; }
  .btn-blue:hover {
    color: #fff;
    background-color: #00607e;
    border-color: #005671; }
  .btn-blue:focus, .btn-blue.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 145, 178, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(38, 145, 178, 0.5); }
  .btn-blue.disabled, .btn-blue:disabled {
    color: #fff;
    background-color: #007da4;
    border-color: #007da4; }
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #005671;
    border-color: #004c64; }
    .btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 145, 178, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(38, 145, 178, 0.5); }

.btn-indigo {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2; }
  .btn-indigo:hover {
    color: #fff;
    background-color: #560bd0;
    border-color: #510bc4; }
  .btn-indigo:focus, .btn-indigo.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(125, 52, 244, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(125, 52, 244, 0.5); }
  .btn-indigo.disabled, .btn-indigo:disabled {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
  .btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #510bc4;
    border-color: #4c0ab8; }
    .btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-indigo.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(125, 52, 244, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(125, 52, 244, 0.5); }

.btn-purple {
  color: #fff;
  background-color: #642f6c;
  border-color: #642f6c; }
  .btn-purple:hover {
    color: #fff;
    background-color: #4b2351;
    border-color: #432048; }
  .btn-purple:focus, .btn-purple.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(123, 78, 130, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(123, 78, 130, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #642f6c;
    border-color: #642f6c; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #432048;
    border-color: #3b1c40; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(123, 78, 130, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(123, 78, 130, 0.5); }

.btn-pink {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c; }
  .btn-pink:hover {
    color: #fff;
    background-color: #e41c78;
    border-color: #d91a72; }
  .btn-pink:focus, .btn-pink.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 91, 157, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(235, 91, 157, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #d91a72;
    border-color: #ce196c; }
    .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 91, 157, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(235, 91, 157, 0.5); }

.btn-red {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-red:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-red:focus, .btn-red.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-red.disabled, .btn-red:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active,
  .show > .btn-red.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-red.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-orange {
  color: #fff;
  background-color: #a9431e;
  border-color: #a9431e; }
  .btn-orange:hover {
    color: #fff;
    background-color: #893618;
    border-color: #7e3216; }
  .btn-orange:focus, .btn-orange.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(182, 95, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(182, 95, 64, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #fff;
    background-color: #a9431e;
    border-color: #a9431e; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #7e3216;
    border-color: #732e14; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(182, 95, 64, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(182, 95, 64, 0.5); }

.btn-yellow {
  color: #222222;
  background-color: #ffb81c;
  border-color: #ffb81c; }
  .btn-yellow:hover {
    color: #222222;
    background-color: #f5a800;
    border-color: #e89f00; }
  .btn-yellow:focus, .btn-yellow.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 162, 29, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(222, 162, 29, 0.5); }
  .btn-yellow.disabled, .btn-yellow:disabled {
    color: #222222;
    background-color: #ffb81c;
    border-color: #ffb81c; }
  .btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-yellow.dropdown-toggle {
    color: #222222;
    background-color: #e89f00;
    border-color: #db9700; }
    .btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-yellow.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 162, 29, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(222, 162, 29, 0.5); }

.btn-green {
  color: #fff;
  background-color: #8f993e;
  border-color: #8f993e; }
  .btn-green:hover {
    color: #fff;
    background-color: #767e33;
    border-color: #6d752f; }
  .btn-green:focus, .btn-green.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(160, 168, 91, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(160, 168, 91, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #fff;
    background-color: #8f993e;
    border-color: #8f993e; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #6d752f;
    border-color: #656c2c; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(160, 168, 91, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(160, 168, 91, 0.5); }

.btn-teal {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997; }
  .btn-teal:hover {
    color: #fff;
    background-color: #1ba87e;
    border-color: #199d76; }
  .btn-teal:focus, .btn-teal.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(65, 209, 167, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(65, 209, 167, 0.5); }
  .btn-teal.disabled, .btn-teal:disabled {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active,
  .show > .btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #199d76;
    border-color: #17926e; }
    .btn-teal:not(:disabled):not(.disabled):active:focus, .btn-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-teal.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(65, 209, 167, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(65, 209, 167, 0.5); }

.btn-cyan {
  color: #fff;
  background-color: #00aead;
  border-color: #00aead; }
  .btn-cyan:hover {
    color: #fff;
    background-color: #008887;
    border-color: #007b7a; }
  .btn-cyan:focus, .btn-cyan.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 186, 185, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(38, 186, 185, 0.5); }
  .btn-cyan.disabled, .btn-cyan:disabled {
    color: #fff;
    background-color: #00aead;
    border-color: #00aead; }
  .btn-cyan:not(:disabled):not(.disabled):active, .btn-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-cyan.dropdown-toggle {
    color: #fff;
    background-color: #007b7a;
    border-color: #006e6e; }
    .btn-cyan:not(:disabled):not(.disabled):active:focus, .btn-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-cyan.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 186, 185, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(38, 186, 185, 0.5); }

.btn-white {
  color: #222222;
  background-color: #fff;
  border-color: #fff; }
  .btn-white:hover {
    color: #222222;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #222222;
    background-color: #fff;
    border-color: #fff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #222222;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 222, 0.5); }

.btn-gray {
  color: #fff;
  background-color: #777777;
  border-color: #777777; }
  .btn-gray:hover {
    color: #fff;
    background-color: #646464;
    border-color: #5e5e5e; }
  .btn-gray:focus, .btn-gray.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #fff;
    background-color: #777777;
    border-color: #777777; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #fff;
    background-color: #5e5e5e;
    border-color: #575757; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5); }

.btn-gray-dark {
  color: #fff;
  background-color: #333333;
  border-color: #333333; }
  .btn-gray-dark:hover {
    color: #fff;
    background-color: #202020;
    border-color: #1a1a1a; }
  .btn-gray-dark:focus, .btn-gray-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }
  .btn-gray-dark.disabled, .btn-gray-dark:disabled {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
  .btn-gray-dark:not(:disabled):not(.disabled):active, .btn-gray-dark:not(:disabled):not(.disabled).active,
  .show > .btn-gray-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1a1a;
    border-color: #131313; }
    .btn-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-gray-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }

.btn-sky {
  color: #222222;
  background-color: #bdd6e6;
  border-color: #bdd6e6; }
  .btn-sky:hover {
    color: #222222;
    background-color: #a1c5db;
    border-color: #98bfd8; }
  .btn-sky:focus, .btn-sky.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(166, 187, 201, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(166, 187, 201, 0.5); }
  .btn-sky.disabled, .btn-sky:disabled {
    color: #222222;
    background-color: #bdd6e6;
    border-color: #bdd6e6; }
  .btn-sky:not(:disabled):not(.disabled):active, .btn-sky:not(:disabled):not(.disabled).active,
  .show > .btn-sky.dropdown-toggle {
    color: #222222;
    background-color: #98bfd8;
    border-color: #8fb9d4; }
    .btn-sky:not(:disabled):not(.disabled):active:focus, .btn-sky:not(:disabled):not(.disabled).active:focus,
    .show > .btn-sky.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(166, 187, 201, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(166, 187, 201, 0.5); }

.btn-lime {
  color: #222222;
  background-color: #b7bf10;
  border-color: #b7bf10; }
  .btn-lime:hover {
    color: #fff;
    background-color: #959c0d;
    border-color: #8a900c; }
  .btn-lime:focus, .btn-lime.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(161, 167, 19, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(161, 167, 19, 0.5); }
  .btn-lime.disabled, .btn-lime:disabled {
    color: #222222;
    background-color: #b7bf10;
    border-color: #b7bf10; }
  .btn-lime:not(:disabled):not(.disabled):active, .btn-lime:not(:disabled):not(.disabled).active,
  .show > .btn-lime.dropdown-toggle {
    color: #fff;
    background-color: #8a900c;
    border-color: #7f840b; }
    .btn-lime:not(:disabled):not(.disabled):active:focus, .btn-lime:not(:disabled):not(.disabled).active:focus,
    .show > .btn-lime.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(161, 167, 19, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(161, 167, 19, 0.5); }

.btn-gold {
  color: #fff;
  background-color: #c99700;
  border-color: #c99700; }
  .btn-gold:hover {
    color: #fff;
    background-color: #a37a00;
    border-color: #967100; }
  .btn-gold:focus, .btn-gold.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(209, 167, 38, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(209, 167, 38, 0.5); }
  .btn-gold.disabled, .btn-gold:disabled {
    color: #fff;
    background-color: #c99700;
    border-color: #c99700; }
  .btn-gold:not(:disabled):not(.disabled):active, .btn-gold:not(:disabled):not(.disabled).active,
  .show > .btn-gold.dropdown-toggle {
    color: #fff;
    background-color: #967100;
    border-color: #896700; }
    .btn-gold:not(:disabled):not(.disabled):active:focus, .btn-gold:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gold.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(209, 167, 38, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(209, 167, 38, 0.5); }

.btn-taupe {
  color: #222222;
  background-color: #a69f88;
  border-color: #a69f88; }
  .btn-taupe:hover {
    color: #fff;
    background-color: #968d72;
    border-color: #90876b; }
  .btn-taupe:focus, .btn-taupe.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(146, 140, 121, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(146, 140, 121, 0.5); }
  .btn-taupe.disabled, .btn-taupe:disabled {
    color: #222222;
    background-color: #a69f88;
    border-color: #a69f88; }
  .btn-taupe:not(:disabled):not(.disabled):active, .btn-taupe:not(:disabled):not(.disabled).active,
  .show > .btn-taupe.dropdown-toggle {
    color: #fff;
    background-color: #90876b;
    border-color: #888066; }
    .btn-taupe:not(:disabled):not(.disabled):active:focus, .btn-taupe:not(:disabled):not(.disabled).active:focus,
    .show > .btn-taupe.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(146, 140, 121, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(146, 140, 121, 0.5); }

.btn-sand {
  color: #222222;
  background-color: #dac79d;
  border-color: #dac79d; }
  .btn-sand:hover {
    color: #222222;
    background-color: #d0b781;
    border-color: #ccb278; }
  .btn-sand:focus, .btn-sand.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(190, 174, 139, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(190, 174, 139, 0.5); }
  .btn-sand.disabled, .btn-sand:disabled {
    color: #222222;
    background-color: #dac79d;
    border-color: #dac79d; }
  .btn-sand:not(:disabled):not(.disabled):active, .btn-sand:not(:disabled):not(.disabled).active,
  .show > .btn-sand.dropdown-toggle {
    color: #222222;
    background-color: #ccb278;
    border-color: #c9ad6f; }
    .btn-sand:not(:disabled):not(.disabled):active:focus, .btn-sand:not(:disabled):not(.disabled).active:focus,
    .show > .btn-sand.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(190, 174, 139, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(190, 174, 139, 0.5); }

.btn-soil {
  color: #fff;
  background-color: #6e4c1e;
  border-color: #6e4c1e; }
  .btn-soil:hover {
    color: #fff;
    background-color: #503716;
    border-color: #463013; }
  .btn-soil:focus, .btn-soil.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(132, 103, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(132, 103, 64, 0.5); }
  .btn-soil.disabled, .btn-soil:disabled {
    color: #fff;
    background-color: #6e4c1e;
    border-color: #6e4c1e; }
  .btn-soil:not(:disabled):not(.disabled):active, .btn-soil:not(:disabled):not(.disabled).active,
  .show > .btn-soil.dropdown-toggle {
    color: #fff;
    background-color: #463013;
    border-color: #3c2910; }
    .btn-soil:not(:disabled):not(.disabled):active:focus, .btn-soil:not(:disabled):not(.disabled).active:focus,
    .show > .btn-soil.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(132, 103, 64, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(132, 103, 64, 0.5); }

.btn-maroon {
  color: #fff;
  background-color: #5d1725;
  border-color: #5d1725; }
  .btn-maroon:hover {
    color: #fff;
    background-color: #3e0f19;
    border-color: #340d15; }
  .btn-maroon:focus, .btn-maroon.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5); }
  .btn-maroon.disabled, .btn-maroon:disabled {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
  .btn-maroon:not(:disabled):not(.disabled):active, .btn-maroon:not(:disabled):not(.disabled).active,
  .show > .btn-maroon.dropdown-toggle {
    color: #fff;
    background-color: #340d15;
    border-color: #2a0a11; }
    .btn-maroon:not(:disabled):not(.disabled):active:focus, .btn-maroon:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(117, 58, 70, 0.5); }

.btn-maroon-light {
  color: #fff;
  background-color: #7a1e30;
  border-color: #7a1e30; }
  .btn-maroon-light:hover {
    color: #fff;
    background-color: #5b1624;
    border-color: #511420; }
  .btn-maroon-light:focus, .btn-maroon-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(142, 64, 79, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(142, 64, 79, 0.5); }
  .btn-maroon-light.disabled, .btn-maroon-light:disabled {
    color: #fff;
    background-color: #7a1e30;
    border-color: #7a1e30; }
  .btn-maroon-light:not(:disabled):not(.disabled):active, .btn-maroon-light:not(:disabled):not(.disabled).active,
  .show > .btn-maroon-light.dropdown-toggle {
    color: #fff;
    background-color: #511420;
    border-color: #47111c; }
    .btn-maroon-light:not(:disabled):not(.disabled):active:focus, .btn-maroon-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-maroon-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(142, 64, 79, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(142, 64, 79, 0.5); }

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000; }
  .btn-black:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-black:focus, .btn-black.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }

.btn-outline-maroon-primary {
  color: #5d1725;
  border-color: #5d1725; }
  .btn-outline-maroon-primary:hover {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
  .btn-outline-maroon-primary:focus, .btn-outline-maroon-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5); }
  .btn-outline-maroon-primary.disabled, .btn-outline-maroon-primary:disabled {
    color: #5d1725;
    background-color: transparent; }
  .btn-outline-maroon-primary:not(:disabled):not(.disabled):active, .btn-outline-maroon-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon-primary.dropdown-toggle {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
    .btn-outline-maroon-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5); }

.btn-outline-maroon-100 {
  color: #96253c;
  border-color: #96253c; }
  .btn-outline-maroon-100:hover {
    color: #fff;
    background-color: #96253c;
    border-color: #96253c; }
  .btn-outline-maroon-100:focus, .btn-outline-maroon-100.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(150, 37, 60, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(150, 37, 60, 0.5); }
  .btn-outline-maroon-100.disabled, .btn-outline-maroon-100:disabled {
    color: #96253c;
    background-color: transparent; }
  .btn-outline-maroon-100:not(:disabled):not(.disabled):active, .btn-outline-maroon-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon-100.dropdown-toggle {
    color: #fff;
    background-color: #96253c;
    border-color: #96253c; }
    .btn-outline-maroon-100:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon-100.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(150, 37, 60, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(150, 37, 60, 0.5); }

.btn-outline-maroon-200 {
  color: #882236;
  border-color: #882236; }
  .btn-outline-maroon-200:hover {
    color: #fff;
    background-color: #882236;
    border-color: #882236; }
  .btn-outline-maroon-200:focus, .btn-outline-maroon-200.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(136, 34, 54, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(136, 34, 54, 0.5); }
  .btn-outline-maroon-200.disabled, .btn-outline-maroon-200:disabled {
    color: #882236;
    background-color: transparent; }
  .btn-outline-maroon-200:not(:disabled):not(.disabled):active, .btn-outline-maroon-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon-200.dropdown-toggle {
    color: #fff;
    background-color: #882236;
    border-color: #882236; }
    .btn-outline-maroon-200:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon-200.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(136, 34, 54, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(136, 34, 54, 0.5); }

.btn-outline-maroon-300 {
  color: #7a1e30;
  border-color: #7a1e30; }
  .btn-outline-maroon-300:hover {
    color: #fff;
    background-color: #7a1e30;
    border-color: #7a1e30; }
  .btn-outline-maroon-300:focus, .btn-outline-maroon-300.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(122, 30, 48, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(122, 30, 48, 0.5); }
  .btn-outline-maroon-300.disabled, .btn-outline-maroon-300:disabled {
    color: #7a1e30;
    background-color: transparent; }
  .btn-outline-maroon-300:not(:disabled):not(.disabled):active, .btn-outline-maroon-300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon-300.dropdown-toggle {
    color: #fff;
    background-color: #7a1e30;
    border-color: #7a1e30; }
    .btn-outline-maroon-300:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon-300.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(122, 30, 48, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(122, 30, 48, 0.5); }

.btn-outline-maroon-400 {
  color: #6b1b2b;
  border-color: #6b1b2b; }
  .btn-outline-maroon-400:hover {
    color: #fff;
    background-color: #6b1b2b;
    border-color: #6b1b2b; }
  .btn-outline-maroon-400:focus, .btn-outline-maroon-400.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(107, 27, 43, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(107, 27, 43, 0.5); }
  .btn-outline-maroon-400.disabled, .btn-outline-maroon-400:disabled {
    color: #6b1b2b;
    background-color: transparent; }
  .btn-outline-maroon-400:not(:disabled):not(.disabled):active, .btn-outline-maroon-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon-400.dropdown-toggle {
    color: #fff;
    background-color: #6b1b2b;
    border-color: #6b1b2b; }
    .btn-outline-maroon-400:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon-400.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(107, 27, 43, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(107, 27, 43, 0.5); }

.btn-outline-maroon-500 {
  color: #5d1725;
  border-color: #5d1725; }
  .btn-outline-maroon-500:hover {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
  .btn-outline-maroon-500:focus, .btn-outline-maroon-500.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5); }
  .btn-outline-maroon-500.disabled, .btn-outline-maroon-500:disabled {
    color: #5d1725;
    background-color: transparent; }
  .btn-outline-maroon-500:not(:disabled):not(.disabled):active, .btn-outline-maroon-500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon-500.dropdown-toggle {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
    .btn-outline-maroon-500:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon-500.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5); }

.btn-outline-maroon-600 {
  color: #4f131f;
  border-color: #4f131f; }
  .btn-outline-maroon-600:hover {
    color: #fff;
    background-color: #4f131f;
    border-color: #4f131f; }
  .btn-outline-maroon-600:focus, .btn-outline-maroon-600.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 19, 31, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(79, 19, 31, 0.5); }
  .btn-outline-maroon-600.disabled, .btn-outline-maroon-600:disabled {
    color: #4f131f;
    background-color: transparent; }
  .btn-outline-maroon-600:not(:disabled):not(.disabled):active, .btn-outline-maroon-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon-600.dropdown-toggle {
    color: #fff;
    background-color: #4f131f;
    border-color: #4f131f; }
    .btn-outline-maroon-600:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon-600.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 19, 31, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(79, 19, 31, 0.5); }

.btn-outline-maroon-700 {
  color: #40101a;
  border-color: #40101a; }
  .btn-outline-maroon-700:hover {
    color: #fff;
    background-color: #40101a;
    border-color: #40101a; }
  .btn-outline-maroon-700:focus, .btn-outline-maroon-700.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(64, 16, 26, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(64, 16, 26, 0.5); }
  .btn-outline-maroon-700.disabled, .btn-outline-maroon-700:disabled {
    color: #40101a;
    background-color: transparent; }
  .btn-outline-maroon-700:not(:disabled):not(.disabled):active, .btn-outline-maroon-700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon-700.dropdown-toggle {
    color: #fff;
    background-color: #40101a;
    border-color: #40101a; }
    .btn-outline-maroon-700:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon-700.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(64, 16, 26, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(64, 16, 26, 0.5); }

.btn-outline-maroon-800 {
  color: #320c14;
  border-color: #320c14; }
  .btn-outline-maroon-800:hover {
    color: #fff;
    background-color: #320c14;
    border-color: #320c14; }
  .btn-outline-maroon-800:focus, .btn-outline-maroon-800.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(50, 12, 20, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(50, 12, 20, 0.5); }
  .btn-outline-maroon-800.disabled, .btn-outline-maroon-800:disabled {
    color: #320c14;
    background-color: transparent; }
  .btn-outline-maroon-800:not(:disabled):not(.disabled):active, .btn-outline-maroon-800:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon-800.dropdown-toggle {
    color: #fff;
    background-color: #320c14;
    border-color: #320c14; }
    .btn-outline-maroon-800:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon-800.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(50, 12, 20, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(50, 12, 20, 0.5); }

.btn-outline-maroon-900 {
  color: #24090e;
  border-color: #24090e; }
  .btn-outline-maroon-900:hover {
    color: #fff;
    background-color: #24090e;
    border-color: #24090e; }
  .btn-outline-maroon-900:focus, .btn-outline-maroon-900.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 9, 14, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(36, 9, 14, 0.5); }
  .btn-outline-maroon-900.disabled, .btn-outline-maroon-900:disabled {
    color: #24090e;
    background-color: transparent; }
  .btn-outline-maroon-900:not(:disabled):not(.disabled):active, .btn-outline-maroon-900:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon-900.dropdown-toggle {
    color: #fff;
    background-color: #24090e;
    border-color: #24090e; }
    .btn-outline-maroon-900:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon-900.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(36, 9, 14, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(36, 9, 14, 0.5); }

.btn-outline-gray-100 {
  color: #f9f9f9;
  border-color: #f9f9f9; }
  .btn-outline-gray-100:hover {
    color: #222222;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
  .btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }
  .btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
    color: #f9f9f9;
    background-color: transparent; }
  .btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-100.dropdown-toggle {
    color: #222222;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
    .btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-100.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }

.btn-outline-gray-200 {
  color: #eeeeee;
  border-color: #eeeeee; }
  .btn-outline-gray-200:hover {
    color: #222222;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5); }
  .btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
    color: #eeeeee;
    background-color: transparent; }
  .btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-200.dropdown-toggle {
    color: #222222;
    background-color: #eeeeee;
    border-color: #eeeeee; }
    .btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-200.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5); }

.btn-outline-gray-300 {
  color: #e1e1e1;
  border-color: #e1e1e1; }
  .btn-outline-gray-300:hover {
    color: #222222;
    background-color: #e1e1e1;
    border-color: #e1e1e1; }
  .btn-outline-gray-300:focus, .btn-outline-gray-300.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 225, 225, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(225, 225, 225, 0.5); }
  .btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled {
    color: #e1e1e1;
    background-color: transparent; }
  .btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-300.dropdown-toggle {
    color: #222222;
    background-color: #e1e1e1;
    border-color: #e1e1e1; }
    .btn-outline-gray-300:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-300.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 225, 225, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(225, 225, 225, 0.5); }

.btn-outline-gray-400 {
  color: #cccccc;
  border-color: #cccccc; }
  .btn-outline-gray-400:hover {
    color: #222222;
    background-color: #cccccc;
    border-color: #cccccc; }
  .btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5); }
  .btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
    color: #cccccc;
    background-color: transparent; }
  .btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-400.dropdown-toggle {
    color: #222222;
    background-color: #cccccc;
    border-color: #cccccc; }
    .btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-400.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5); }

.btn-outline-gray-500 {
  color: #aaaaaa;
  border-color: #aaaaaa; }
  .btn-outline-gray-500:hover {
    color: #222222;
    background-color: #aaaaaa;
    border-color: #aaaaaa; }
  .btn-outline-gray-500:focus, .btn-outline-gray-500.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(170, 170, 170, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(170, 170, 170, 0.5); }
  .btn-outline-gray-500.disabled, .btn-outline-gray-500:disabled {
    color: #aaaaaa;
    background-color: transparent; }
  .btn-outline-gray-500:not(:disabled):not(.disabled):active, .btn-outline-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-500.dropdown-toggle {
    color: #222222;
    background-color: #aaaaaa;
    border-color: #aaaaaa; }
    .btn-outline-gray-500:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-500.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(170, 170, 170, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(170, 170, 170, 0.5); }

.btn-outline-gray-600 {
  color: #777777;
  border-color: #777777; }
  .btn-outline-gray-600:hover {
    color: #fff;
    background-color: #777777;
    border-color: #777777; }
  .btn-outline-gray-600:focus, .btn-outline-gray-600.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(119, 119, 119, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(119, 119, 119, 0.5); }
  .btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled {
    color: #777777;
    background-color: transparent; }
  .btn-outline-gray-600:not(:disabled):not(.disabled):active, .btn-outline-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #777777;
    border-color: #777777; }
    .btn-outline-gray-600:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-600.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(119, 119, 119, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(119, 119, 119, 0.5); }

.btn-outline-gray-700 {
  color: #555555;
  border-color: #555555; }
  .btn-outline-gray-700:hover {
    color: #fff;
    background-color: #555555;
    border-color: #555555; }
  .btn-outline-gray-700:focus, .btn-outline-gray-700.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5); }
  .btn-outline-gray-700.disabled, .btn-outline-gray-700:disabled {
    color: #555555;
    background-color: transparent; }
  .btn-outline-gray-700:not(:disabled):not(.disabled):active, .btn-outline-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #555555;
    border-color: #555555; }
    .btn-outline-gray-700:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-700.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5); }

.btn-outline-gray-800 {
  color: #333333;
  border-color: #333333; }
  .btn-outline-gray-800:hover {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
  .btn-outline-gray-800:focus, .btn-outline-gray-800.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }
  .btn-outline-gray-800.disabled, .btn-outline-gray-800:disabled {
    color: #333333;
    background-color: transparent; }
  .btn-outline-gray-800:not(:disabled):not(.disabled):active, .btn-outline-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
    .btn-outline-gray-800:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-800.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.btn-outline-gray-900 {
  color: #222222;
  border-color: #222222; }
  .btn-outline-gray-900:hover {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }
  .btn-outline-gray-900:focus, .btn-outline-gray-900.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }
  .btn-outline-gray-900.disabled, .btn-outline-gray-900:disabled {
    color: #222222;
    background-color: transparent; }
  .btn-outline-gray-900:not(:disabled):not(.disabled):active, .btn-outline-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-900.dropdown-toggle {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }
    .btn-outline-gray-900:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-900.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5); }

.btn-outline-blue {
  color: #007da4;
  border-color: #007da4; }
  .btn-outline-blue:hover {
    color: #fff;
    background-color: #007da4;
    border-color: #007da4; }
  .btn-outline-blue:focus, .btn-outline-blue.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.5); }
  .btn-outline-blue.disabled, .btn-outline-blue:disabled {
    color: #007da4;
    background-color: transparent; }
  .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: #007da4;
    border-color: #007da4; }
    .btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-blue.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(0, 125, 164, 0.5); }

.btn-outline-indigo {
  color: #6610f2;
  border-color: #6610f2; }
  .btn-outline-indigo:hover {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
  .btn-outline-indigo:focus, .btn-outline-indigo.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }
  .btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
    color: #6610f2;
    background-color: transparent; }
  .btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-indigo.dropdown-toggle {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
    .btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-indigo.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }

.btn-outline-purple {
  color: #642f6c;
  border-color: #642f6c; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #642f6c;
    border-color: #642f6c; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(100, 47, 108, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(100, 47, 108, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #642f6c;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #642f6c;
    border-color: #642f6c; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(100, 47, 108, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(100, 47, 108, 0.5); }

.btn-outline-pink {
  color: #e83e8c;
  border-color: #e83e8c; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #e83e8c;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }

.btn-outline-red {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-red:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-red:focus, .btn-outline-red.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-red.disabled, .btn-outline-red:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-red.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-red:not(:disabled):not(.disabled):active:focus, .btn-outline-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-red.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-orange {
  color: #a9431e;
  border-color: #a9431e; }
  .btn-outline-orange:hover {
    color: #fff;
    background-color: #a9431e;
    border-color: #a9431e; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(169, 67, 30, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(169, 67, 30, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #a9431e;
    background-color: transparent; }
  .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #fff;
    background-color: #a9431e;
    border-color: #a9431e; }
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(169, 67, 30, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(169, 67, 30, 0.5); }

.btn-outline-yellow {
  color: #ffb81c;
  border-color: #ffb81c; }
  .btn-outline-yellow:hover {
    color: #222222;
    background-color: #ffb81c;
    border-color: #ffb81c; }
  .btn-outline-yellow:focus, .btn-outline-yellow.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.5); }
  .btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
    color: #ffb81c;
    background-color: transparent; }
  .btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-yellow.dropdown-toggle {
    color: #222222;
    background-color: #ffb81c;
    border-color: #ffb81c; }
    .btn-outline-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-yellow.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.5); }

.btn-outline-green {
  color: #8f993e;
  border-color: #8f993e; }
  .btn-outline-green:hover {
    color: #fff;
    background-color: #8f993e;
    border-color: #8f993e; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #8f993e;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #fff;
    background-color: #8f993e;
    border-color: #8f993e; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(143, 153, 62, 0.5); }

.btn-outline-teal {
  color: #20c997;
  border-color: #20c997; }
  .btn-outline-teal:hover {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-outline-teal:focus, .btn-outline-teal.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }
  .btn-outline-teal.disabled, .btn-outline-teal:disabled {
    color: #20c997;
    background-color: transparent; }
  .btn-outline-teal:not(:disabled):not(.disabled):active, .btn-outline-teal:not(:disabled):not(.disabled).active,
  .show > .btn-outline-teal.dropdown-toggle {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
    .btn-outline-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-teal.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }

.btn-outline-cyan {
  color: #00aead;
  border-color: #00aead; }
  .btn-outline-cyan:hover {
    color: #fff;
    background-color: #00aead;
    border-color: #00aead; }
  .btn-outline-cyan:focus, .btn-outline-cyan.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 174, 173, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 174, 173, 0.5); }
  .btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
    color: #00aead;
    background-color: transparent; }
  .btn-outline-cyan:not(:disabled):not(.disabled):active, .btn-outline-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-outline-cyan.dropdown-toggle {
    color: #fff;
    background-color: #00aead;
    border-color: #00aead; }
    .btn-outline-cyan:not(:disabled):not(.disabled):active:focus, .btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-cyan.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 174, 173, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(0, 174, 173, 0.5); }

.btn-outline-white {
  color: #fff;
  border-color: #fff; }
  .btn-outline-white:hover {
    color: #222222;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #222222;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-gray {
  color: #777777;
  border-color: #777777; }
  .btn-outline-gray:hover {
    color: #fff;
    background-color: #777777;
    border-color: #777777; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(119, 119, 119, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(119, 119, 119, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #777777;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #fff;
    background-color: #777777;
    border-color: #777777; }
    .btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(119, 119, 119, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(119, 119, 119, 0.5); }

.btn-outline-gray-dark {
  color: #333333;
  border-color: #333333; }
  .btn-outline-gray-dark:hover {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
  .btn-outline-gray-dark:focus, .btn-outline-gray-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }
  .btn-outline-gray-dark.disabled, .btn-outline-gray-dark:disabled {
    color: #333333;
    background-color: transparent; }
  .btn-outline-gray-dark:not(:disabled):not(.disabled):active, .btn-outline-gray-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-dark.dropdown-toggle {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
    .btn-outline-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.btn-outline-sky {
  color: #bdd6e6;
  border-color: #bdd6e6; }
  .btn-outline-sky:hover {
    color: #222222;
    background-color: #bdd6e6;
    border-color: #bdd6e6; }
  .btn-outline-sky:focus, .btn-outline-sky.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(189, 214, 230, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(189, 214, 230, 0.5); }
  .btn-outline-sky.disabled, .btn-outline-sky:disabled {
    color: #bdd6e6;
    background-color: transparent; }
  .btn-outline-sky:not(:disabled):not(.disabled):active, .btn-outline-sky:not(:disabled):not(.disabled).active,
  .show > .btn-outline-sky.dropdown-toggle {
    color: #222222;
    background-color: #bdd6e6;
    border-color: #bdd6e6; }
    .btn-outline-sky:not(:disabled):not(.disabled):active:focus, .btn-outline-sky:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-sky.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(189, 214, 230, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(189, 214, 230, 0.5); }

.btn-outline-lime {
  color: #b7bf10;
  border-color: #b7bf10; }
  .btn-outline-lime:hover {
    color: #222222;
    background-color: #b7bf10;
    border-color: #b7bf10; }
  .btn-outline-lime:focus, .btn-outline-lime.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(183, 191, 16, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(183, 191, 16, 0.5); }
  .btn-outline-lime.disabled, .btn-outline-lime:disabled {
    color: #b7bf10;
    background-color: transparent; }
  .btn-outline-lime:not(:disabled):not(.disabled):active, .btn-outline-lime:not(:disabled):not(.disabled).active,
  .show > .btn-outline-lime.dropdown-toggle {
    color: #222222;
    background-color: #b7bf10;
    border-color: #b7bf10; }
    .btn-outline-lime:not(:disabled):not(.disabled):active:focus, .btn-outline-lime:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-lime.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(183, 191, 16, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(183, 191, 16, 0.5); }

.btn-outline-gold {
  color: #c99700;
  border-color: #c99700; }
  .btn-outline-gold:hover {
    color: #fff;
    background-color: #c99700;
    border-color: #c99700; }
  .btn-outline-gold:focus, .btn-outline-gold.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(201, 151, 0, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(201, 151, 0, 0.5); }
  .btn-outline-gold.disabled, .btn-outline-gold:disabled {
    color: #c99700;
    background-color: transparent; }
  .btn-outline-gold:not(:disabled):not(.disabled):active, .btn-outline-gold:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gold.dropdown-toggle {
    color: #fff;
    background-color: #c99700;
    border-color: #c99700; }
    .btn-outline-gold:not(:disabled):not(.disabled):active:focus, .btn-outline-gold:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gold.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(201, 151, 0, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(201, 151, 0, 0.5); }

.btn-outline-taupe {
  color: #a69f88;
  border-color: #a69f88; }
  .btn-outline-taupe:hover {
    color: #222222;
    background-color: #a69f88;
    border-color: #a69f88; }
  .btn-outline-taupe:focus, .btn-outline-taupe.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(166, 159, 136, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(166, 159, 136, 0.5); }
  .btn-outline-taupe.disabled, .btn-outline-taupe:disabled {
    color: #a69f88;
    background-color: transparent; }
  .btn-outline-taupe:not(:disabled):not(.disabled):active, .btn-outline-taupe:not(:disabled):not(.disabled).active,
  .show > .btn-outline-taupe.dropdown-toggle {
    color: #222222;
    background-color: #a69f88;
    border-color: #a69f88; }
    .btn-outline-taupe:not(:disabled):not(.disabled):active:focus, .btn-outline-taupe:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-taupe.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(166, 159, 136, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(166, 159, 136, 0.5); }

.btn-outline-sand {
  color: #dac79d;
  border-color: #dac79d; }
  .btn-outline-sand:hover {
    color: #222222;
    background-color: #dac79d;
    border-color: #dac79d; }
  .btn-outline-sand:focus, .btn-outline-sand.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 199, 157, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(218, 199, 157, 0.5); }
  .btn-outline-sand.disabled, .btn-outline-sand:disabled {
    color: #dac79d;
    background-color: transparent; }
  .btn-outline-sand:not(:disabled):not(.disabled):active, .btn-outline-sand:not(:disabled):not(.disabled).active,
  .show > .btn-outline-sand.dropdown-toggle {
    color: #222222;
    background-color: #dac79d;
    border-color: #dac79d; }
    .btn-outline-sand:not(:disabled):not(.disabled):active:focus, .btn-outline-sand:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-sand.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 199, 157, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(218, 199, 157, 0.5); }

.btn-outline-soil {
  color: #6e4c1e;
  border-color: #6e4c1e; }
  .btn-outline-soil:hover {
    color: #fff;
    background-color: #6e4c1e;
    border-color: #6e4c1e; }
  .btn-outline-soil:focus, .btn-outline-soil.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(110, 76, 30, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(110, 76, 30, 0.5); }
  .btn-outline-soil.disabled, .btn-outline-soil:disabled {
    color: #6e4c1e;
    background-color: transparent; }
  .btn-outline-soil:not(:disabled):not(.disabled):active, .btn-outline-soil:not(:disabled):not(.disabled).active,
  .show > .btn-outline-soil.dropdown-toggle {
    color: #fff;
    background-color: #6e4c1e;
    border-color: #6e4c1e; }
    .btn-outline-soil:not(:disabled):not(.disabled):active:focus, .btn-outline-soil:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-soil.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(110, 76, 30, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(110, 76, 30, 0.5); }

.btn-outline-maroon {
  color: #5d1725;
  border-color: #5d1725; }
  .btn-outline-maroon:hover {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
  .btn-outline-maroon:focus, .btn-outline-maroon.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5); }
  .btn-outline-maroon.disabled, .btn-outline-maroon:disabled {
    color: #5d1725;
    background-color: transparent; }
  .btn-outline-maroon:not(:disabled):not(.disabled):active, .btn-outline-maroon:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon.dropdown-toggle {
    color: #fff;
    background-color: #5d1725;
    border-color: #5d1725; }
    .btn-outline-maroon:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(93, 23, 37, 0.5); }

.btn-outline-maroon-light {
  color: #7a1e30;
  border-color: #7a1e30; }
  .btn-outline-maroon-light:hover {
    color: #fff;
    background-color: #7a1e30;
    border-color: #7a1e30; }
  .btn-outline-maroon-light:focus, .btn-outline-maroon-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(122, 30, 48, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(122, 30, 48, 0.5); }
  .btn-outline-maroon-light.disabled, .btn-outline-maroon-light:disabled {
    color: #7a1e30;
    background-color: transparent; }
  .btn-outline-maroon-light:not(:disabled):not(.disabled):active, .btn-outline-maroon-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-maroon-light.dropdown-toggle {
    color: #fff;
    background-color: #7a1e30;
    border-color: #7a1e30; }
    .btn-outline-maroon-light:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-maroon-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(122, 30, 48, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(122, 30, 48, 0.5); }

.btn-outline-black {
  color: #000;
  border-color: #000; }
  .btn-outline-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #000;
    background-color: transparent; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000; }
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.card-body p {
  line-height: 1.4; }

.card-bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative; }

a.card {
  text-decoration: none;
  color: #000;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s; }
  a.card:hover, a.card:focus {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02); }
  a.card .card-body h1, a.card .card-body h2, a.card .card-body h3, a.card .card-body h4, a.card .card-body h5, a.card .card-body h6,
  a.card .card-body .h1, a.card .card-body .h2, a.card .card-body .h3, a.card .card-body .h4, a.card .card-body .h5, a.card .card-body .h6 {
    color: #7a1e30; }

.card.no-container {
  border: none;
  background: transparent; }
  .card.no-container .card-body {
    padding: 0; }

#main-breadcrumb {
  height: auto;
  line-height: 1.75;
  font-size: 0.8rem;
  font-weight: 300;
  padding: 1rem 0;
  margin: 0;
  background-color: transparent; }
  #main-breadcrumb,
  #main-breadcrumb .container-fluid,
  #main-breadcrumb .breadcrumb {
    height: auto; }
  #main-breadcrumb .breadcrumb {
    margin: 0;
    padding: 0;
    border-radius: none;
    background: transparent; }
    #main-breadcrumb .breadcrumb > li,
    #main-breadcrumb .breadcrumb > li.active {
      color: #555; }
      #main-breadcrumb .breadcrumb > li a,
      #main-breadcrumb .breadcrumb > li.active a {
        color: #333; }
        #main-breadcrumb .breadcrumb > li a:hover, #main-breadcrumb .breadcrumb > li a:focus,
        #main-breadcrumb .breadcrumb > li.active a:hover,
        #main-breadcrumb .breadcrumb > li.active a:focus {
          color: #000; }

.media-bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative; }
  .media-bg-img.size-65 {
    width: 65px;
    height: 65px; }
  .media-bg-img.size-75 {
    width: 75px;
    height: 75px; }
  .media-bg-img.size-100 {
    width: 100px;
    height: 100px; }
  .media-bg-img.size-125 {
    width: 125px;
    height: 125px; }
  .media-bg-img.size-150 {
    width: 150px;
    height: 150px; }

.files-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0; }
  .files-list li {
    position: relative;
    display: block;
    margin: 0;
    padding: 0; }
  .files-list li:first-child a,
  .files-list > a:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .files-list li:last-child a,
  .files-list > a:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .files-list a {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: #fff;
    padding-left: 3rem;
    text-decoration: none; }
    .files-list a:hover, .files-list a:focus {
      background-color: #f9f9f9;
      color: #5d1725;
      text-decoration: none; }
    .files-list a:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute;
      left: 1.25rem;
      content: "\f0c1"; }
    .files-list a[href$=".doc"]:before {
      content: "\f1c2"; }
    .files-list a[href$=".docx"]:before {
      content: "\f1c2"; }
    .files-list a[href$=".xls"]:before {
      content: "\f1c3"; }
    .files-list a[href$=".xlsx"]:before {
      content: "\f1c3"; }
    .files-list a[href$=".ppt"]:before {
      content: "\f1c4"; }
    .files-list a[href$=".pptx"]:before {
      content: "\f1c4"; }
    .files-list a[href$=".pdf"]:before {
      content: "\f1c1"; }
    .files-list a[href$=".jpg"]:before {
      content: "\f1c5"; }
    .files-list a[href$=".png"]:before {
      content: "\f1c5"; }
    .files-list a[href$=".mov"]:before {
      content: "\f1c8"; }
    .files-list a[href$=".mp4"]:before {
      content: "\f1c8"; }
    .files-list a[href$=".wmv"]:before {
      content: "\f1c8"; }
    .files-list a[href$=".wav"]:before {
      content: "\f1c7"; }
    .files-list a[href$=".mp3"]:before {
      content: "\f1c7"; }

#msu-header {
  position: relative;
  border-bottom: 4px solid #320c14;
  background-color: #4f131f;
  background-image: url("//cdn01.its.msstate.edu/i/msstatehighfive/img/background/bg_header_maroon.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  color: #fff;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75; }
  #msu-header,
  #msu-header > .container-fluid,
  #msu-header > .container-fluid > .row {
    height: 112px; }
  @media (min-width: 768px) {
    #msu-header,
    #msu-header > .container-fluid,
    #msu-header > .container-fluid > .row {
      height: 146px; } }
  #msu-header.msu-header-white {
    border-bottom: 4px solid #7a1e30;
    background-color: #fff;
    background-image: url("//cdn01.its.msstate.edu/i/msstatehighfive/img/background/bg_header_white.jpg");
    color: #000; }
  #msu-header.msu-header-black {
    border-bottom: 4px solid #111;
    background-color: #000;
    background-image: url("//cdn01.its.msstate.edu/i/msstatehighfive/img/background/bg_header_black.jpg");
    color: #fff; }
  #msu-header .logo-container {
    padding-top: 25px;
    padding-bottom: 25px; }
    #msu-header .logo-container a {
      display: block;
      position: relative;
      z-index: 1;
      color: #fff; }
      #msu-header .logo-container a img {
        max-height: 70px; }
        @media (min-width: 433px) {
          #msu-header .logo-container a img {
            max-width: 372.374px; } }
        @media (min-width: 768px) {
          #msu-header .logo-container a img {
            max-width: 478.767px;
            max-height: 90px; } }
  #msu-header.msu-header-white .logo-container a {
    color: #000; }
  #msu-header.msu-header-white .logo-container a {
    color: #fff; }
  #msu-header .secondary {
    height: 56px;
    font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }
    #msu-header .secondary .quick-links-container .nav > li.spacer {
      margin-right: 10px; }
    #msu-header .secondary .quick-links-container .nav > li > a {
      display: block;
      height: 28px;
      line-height: 28px;
      font-size: 0.7rem;
      padding: 0 7px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }
      @media (min-width: 768px) {
        #msu-header .secondary .quick-links-container .nav > li > a {
          padding: 0 10px; } }
      @media (min-width: 992px) {
        #msu-header .secondary .quick-links-container .nav > li > a {
          padding: 0 15px; } }
    #msu-header .secondary .concentration-links-container .nav > li > a {
      display: block;
      padding: 0 20px;
      height: 28px;
      line-height: 28px;
      font-size: 1rem;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }
    #msu-header .secondary .nav:not(.nav-social) > li > a {
      color: #fff;
      background: #40101a; }
      #msu-header .secondary .nav:not(.nav-social) > li > a:hover, #msu-header .secondary .nav:not(.nav-social) > li > a:focus {
        background: #fff;
        color: #7a1e30; }
    #msu-header .secondary .color-black .nav:not(.nav-social) > li:not(.university) > a {
      color: #fff;
      background: #000; }
      #msu-header .secondary .color-black .nav:not(.nav-social) > li:not(.university) > a:hover, #msu-header .secondary .color-black .nav:not(.nav-social) > li:not(.university) > a:focus {
        background: #eeeeee;
        color: #000; }
    #msu-header .secondary .color-white .nav:not(.nav-social) > li:not(.university) > a {
      color: #333333;
      background: #fff; }
      #msu-header .secondary .color-white .nav:not(.nav-social) > li:not(.university) > a:hover, #msu-header .secondary .color-white .nav:not(.nav-social) > li:not(.university) > a:focus {
        background: #5d1725;
        color: #fff; }
    #msu-header .secondary .color-gray .nav:not(.nav-social) > li:not(.university) > a {
      color: #000;
      background: #eeeeee; }
      #msu-header .secondary .color-gray .nav:not(.nav-social) > li:not(.university) > a:hover, #msu-header .secondary .color-gray .nav:not(.nav-social) > li:not(.university) > a:focus {
        background: #fff;
        color: #000; }
  #msu-header .search .search-form label {
    color: #fff; }
  #msu-header .search .search-form .form-control {
    position: relative;
    background-color: #fff;
    color: #333333;
    font-size: 0.8rem;
    width: auto;
    border: none;
    border-radius: 0;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s; }
    #msu-header .search .search-form .form-control:focus {
      -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
      z-index: 1; }
  #msu-header .search .search-form input.form-control {
    width: 100%;
    margin-right: -1px; }
  #msu-header.msu-header-white .search .search-form label {
    color: #000; }
  #msu-header.msu-header-white .search .search-form .form-control {
    border: 1px solid #e1e1e1; }
  #msu-header.msu-header-white .search .search-form input.form-control {
    border-right: none; }
  #msu-header.msu-header-white .search .search-form button.form-control {
    border-left: none; }

#msu-footer {
  background: #000;
  color: #fff;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75;
  padding-top: 5.25rem;
  padding-bottom: 5.25rem; }
  #msu-footer p,
  #msu-footer li {
    color: #fff; }
  #msu-footer a {
    color: #fff; }
    #msu-footer a:hover, #msu-footer a:focus {
      color: #fff; }
  #msu-footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  #msu-footer .address address {
    position: relative;
    background-color: #191919;
    border-radius: 0;
    border: none; }
    #msu-footer .address address > .card-body {
      padding-top: 2rem;
      padding-bottom: 2rem; }
    @media (max-width: 575.98px) {
      #msu-footer .address address {
        font-size: 0.8rem; } }
    #msu-footer .address address .logo {
      display: block;
      padding-right: 1rem; }
    #msu-footer .address address .nav-contact-method a {
      display: block;
      height: 56px;
      line-height: 56px;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      background-color: #222222;
      color: #fff;
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
      border-bottom: 1px solid #111;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      text-decoration: none; }
      #msu-footer .address address .nav-contact-method a:hover, #msu-footer .address address .nav-contact-method a:focus {
        background-color: white;
        color: #000; }
      #msu-footer .address address .nav-contact-method a .icon {
        padding: 0 1rem;
        text-align: center;
        font-size: 1.25rem; }
      #msu-footer .address address .nav-contact-method a .col {
        padding: 0 1rem 0 0; }
  #msu-footer .links {
    font-weight: bold; }
    #msu-footer .links > div {
      padding-top: 2rem; }
  #msu-footer .logo-footer {
    text-align: center;
    margin-top: 3.5rem; }
    @media (min-width: 992px) {
      #msu-footer .logo-footer {
        text-align: left; } }
    @media (min-width: 1200px) {
      #msu-footer .logo-footer {
        margin-top: 0; } }
    #msu-footer .logo-footer > a > img {
      max-width: 240px;
      max-height: 80px; }
  #msu-footer .disclosure {
    padding-top: 3.5rem;
    border-top: 1px solid #222222;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    font-size: 0.75rem;
    text-align: center; }
    #msu-footer .disclosure p {
      color: #cccccc;
      margin-bottom: 0.875rem; }
    #msu-footer .disclosure a {
      color: #cccccc;
      white-space: nowrap;
      text-decoration: underline; }
      #msu-footer .disclosure a:hover, #msu-footer .disclosure a:focus {
        color: #fff; }
    @media (min-width: 992px) {
      #msu-footer .disclosure {
        text-align: left; } }

#user-menu {
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0; }
  #user-menu,
  #user-menu .container-fluid {
    height: 28px; }
  #user-menu .navbar-nav {
    width: 100%; }
  #user-menu .navbar-nav > li > a {
    padding: 0 25px;
    height: 28px;
    border-right: 1px solid transparent;
    font-size: 0.7rem;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    -webkit-transition: background .15s ease-in-out;
    -o-transition: background .15s ease-in-out;
    transition: background .15s ease-in-out; }
  #user-menu .navbar-nav > li:first-child > a {
    border-left: 1px solid transparent; }

#user-menu {
  background-color: #000; }
  #user-menu .navbar-nav > li > a {
    border-color: rgba(255, 255, 255, 0.14) !important;
    color: #fff; }
  #user-menu .navbar-nav > li:hover > a,
  #user-menu .navbar-nav > li:focus > a,
  #user-menu .navbar-nav > li > a:hover,
  #user-menu .navbar-nav > li > a:focus {
    background-color: #eeeeee;
    color: #000; }

@media (max-width: 767.98px) {
  body {
    padding-top: 50px; } }

body.no-scroll {
  overflow: hidden; }

#mobile-menu.navbar {
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0;
  -webkit-transition: top .15s;
  -o-transition: top .15s;
  transition: top .15s; }
  #mobile-menu.navbar.fixed-top.scroll-hide {
    top: -50px; }
  #mobile-menu.navbar .btn-container {
    background-color: #000;
    height: 50px;
    overflow: hidden; }
    #mobile-menu.navbar .btn-container .btn {
      height: 50px;
      line-height: 50px;
      padding: 0 1rem;
      border-right: 1px solid #333333;
      font-size: 0.8rem; }
      #mobile-menu.navbar .btn-container .btn:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
        box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
    #mobile-menu.navbar .btn-container .hamburger {
      position: relative;
      z-index: 9999; }
      #mobile-menu.navbar .btn-container .hamburger.is-active {
        position: fixed;
        top: 0;
        right: 0; }
  #mobile-menu.navbar .menu-container {
    position: fixed;
    z-index: 9998;
    visibility: hidden;
    top: 0;
    right: auto;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    #mobile-menu.navbar .menu-container:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.92);
      opacity: 0;
      -webkit-transition: all 0.5s cubic-bezier(0.25, 0.25, 0.665, 1);
      -o-transition: all 0.5s cubic-bezier(0.25, 0.25, 0.665, 1);
      transition: all 0.5s cubic-bezier(0.25, 0.25, 0.665, 1); }
    #mobile-menu.navbar .menu-container.is-active {
      left: 0;
      visibility: visible; }
      #mobile-menu.navbar .menu-container.is-active:before {
        opacity: 1; }
  #mobile-menu.navbar .navbar-nav {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 75px 0; }
    #mobile-menu.navbar .navbar-nav > li {
      width: 100%;
      -webkit-transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1);
      -o-transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1);
      transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1); }
      #mobile-menu.navbar .navbar-nav > li:last-child {
        border: none; }
      #mobile-menu.navbar .navbar-nav > li.nav-spacer {
        padding: 1rem 0; }
      #mobile-menu.navbar .navbar-nav > li > a {
        position: relative;
        display: block;
        width: 100%;
        text-align: left;
        margin: 0;
        padding: 0.75rem 15% 0.75rem 10%;
        font-size: 1.2rem;
        line-height: 1.3;
        color: #fff;
        text-decoration: none;
        opacity: 0;
        -webkit-transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1);
        -o-transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1);
        transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1); }
        #mobile-menu.navbar .navbar-nav > li > a:hover, #mobile-menu.navbar .navbar-nav > li > a:focus {
          text-decoration: none;
          color: #fff; }
        #mobile-menu.navbar .navbar-nav > li > a:after {
          content: '';
          position: absolute;
          bottom: .4rem;
          left: 10%;
          padding: 0;
          margin: 0 90% 0 0;
          width: 15px;
          height: 2px;
          border: none;
          background: #fff;
          opacity: 0;
          -webkit-transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1);
          -o-transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1);
          transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1); }
        #mobile-menu.navbar .navbar-nav > li > a:hover:after, #mobile-menu.navbar .navbar-nav > li > a:focus:after {
          opacity: 1; }
        #mobile-menu.navbar .navbar-nav > li > a > span {
          position: absolute;
          right: 15%;
          font-size: 0.9rem;
          line-height: 2;
          -webkit-transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1);
          -o-transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1);
          transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1); }
      #mobile-menu.navbar .navbar-nav > li:nth-child(1) > a {
        -webkit-transition-delay: 0.1s;
        -o-transition-delay: 0.1s;
        transition-delay: 0.1s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(2) > a {
        -webkit-transition-delay: 0.2s;
        -o-transition-delay: 0.2s;
        transition-delay: 0.2s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(3) > a {
        -webkit-transition-delay: 0.3s;
        -o-transition-delay: 0.3s;
        transition-delay: 0.3s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(4) > a {
        -webkit-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
        transition-delay: 0.4s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(5) > a {
        -webkit-transition-delay: 0.5s;
        -o-transition-delay: 0.5s;
        transition-delay: 0.5s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(6) > a {
        -webkit-transition-delay: 0.6s;
        -o-transition-delay: 0.6s;
        transition-delay: 0.6s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(7) > a {
        -webkit-transition-delay: 0.7s;
        -o-transition-delay: 0.7s;
        transition-delay: 0.7s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(8) > a {
        -webkit-transition-delay: 0.8s;
        -o-transition-delay: 0.8s;
        transition-delay: 0.8s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(9) > a {
        -webkit-transition-delay: 0.9s;
        -o-transition-delay: 0.9s;
        transition-delay: 0.9s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(10) > a {
        -webkit-transition-delay: 1s;
        -o-transition-delay: 1s;
        transition-delay: 1s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(11) > a {
        -webkit-transition-delay: 1.1s;
        -o-transition-delay: 1.1s;
        transition-delay: 1.1s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(12) > a {
        -webkit-transition-delay: 1.2s;
        -o-transition-delay: 1.2s;
        transition-delay: 1.2s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(13) > a {
        -webkit-transition-delay: 1.3s;
        -o-transition-delay: 1.3s;
        transition-delay: 1.3s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(14) > a {
        -webkit-transition-delay: 1.4s;
        -o-transition-delay: 1.4s;
        transition-delay: 1.4s; }
      #mobile-menu.navbar .navbar-nav > li:nth-child(15) > a {
        -webkit-transition-delay: 1.5s;
        -o-transition-delay: 1.5s;
        transition-delay: 1.5s; }
      #mobile-menu.navbar .navbar-nav > li.dropdown > a {
        padding: 0.75rem 25% 0.75rem 10%; }
        #mobile-menu.navbar .navbar-nav > li.dropdown > a > .fa-external-link-alt {
          display: none; }
      #mobile-menu.navbar .navbar-nav > li > .dropdown-menu {
        border: none;
        border-radius: 0;
        margin: 0;
        font-size: 0.9rem;
        width: 100%;
        background: transparent;
        color: #fff;
        text-align: left; }
        #mobile-menu.navbar .navbar-nav > li > .dropdown-menu > a,
        #mobile-menu.navbar .navbar-nav > li > .dropdown-menu > .dropdown-item {
          white-space: normal;
          padding: 0.3rem 15% 0.3rem 10%;
          background: transparent;
          color: #fff; }
        #mobile-menu.navbar .navbar-nav > li > .dropdown-menu .dropdown-divider {
          display: none; }
      #mobile-menu.navbar .navbar-nav > li.show {
        padding: 2rem 0; }
        #mobile-menu.navbar .navbar-nav > li.show > a > .fas {
          -webkit-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          transform: rotate(135deg); }
  #mobile-menu.navbar .menu-container.is-active .navbar-nav > li > a {
    opacity: 1; }

.hamburger {
  padding: 18px 15px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  -o-transition-property: opacity, filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }
  .hamburger .hamburger-box {
    width: 23px;
    height: 14px;
    display: block;
    position: relative; }
    .hamburger .hamburger-box .hamburger-inner {
      display: block;
      top: 50%;
      margin-top: -1px; }
      .hamburger .hamburger-box .hamburger-inner, .hamburger .hamburger-box .hamburger-inner::before, .hamburger .hamburger-box .hamburger-inner::after {
        width: 23px;
        height: 2px;
        background-color: #fff;
        border-radius: 0;
        position: absolute;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        -o-transition-property: transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        -webkit-transition-duration: 0.15s;
        -o-transition-duration: 0.15s;
        transition-duration: 0.15s;
        -webkit-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        transition-timing-function: ease; }
      .hamburger .hamburger-box .hamburger-inner.hamburger-gray, .hamburger .hamburger-box .hamburger-inner.hamburger-gray::before, .hamburger .hamburger-box .hamburger-inner.hamburger-gray::after {
        background-color: #555555; }
      .hamburger .hamburger-box .hamburger-inner::before, .hamburger .hamburger-box .hamburger-inner::after {
        content: "";
        display: block; }
      .hamburger .hamburger-box .hamburger-inner::before {
        top: -6px; }
      .hamburger .hamburger-box .hamburger-inner::after {
        bottom: -6px; }

.hamburger--spin .hamburger-inner {
  -webkit-transition-duration: 0.22s;
  -o-transition-duration: 0.22s;
  transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    -o-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    -webkit-transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -o-transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    -o-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

#main-menu.navbar {
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0; }
  #main-menu.navbar,
  #main-menu.navbar .container-fluid,
  #main-menu.navbar .menu-container {
    height: 50px; }
  #main-menu.navbar .menu-container,
  #main-menu.navbar .navbar-nav {
    width: 100%; }
  #main-menu.navbar .disable-dropdown .dropdown-menu {
    display: none !important; }
  #main-menu.navbar .navbar-nav > li {
    position: relative;
    width: auto; }
    @media (min-width: 992px) {
      #main-menu.navbar .navbar-nav > li {
        width: 14.28571%; } }
    #main-menu.navbar .navbar-nav > li > a {
      position: relative;
      z-index: 1001;
      display: block;
      padding: 0 10px;
      height: 50px;
      border-right: 1px solid transparent;
      font-size: 0.75rem;
      line-height: 50px;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      -webkit-transition: background .15s ease-in-out;
      -o-transition: background .15s ease-in-out;
      transition: background .15s ease-in-out; }
      @media (min-width: 768px) {
        #main-menu.navbar .navbar-nav > li > a {
          padding: 0 15px;
          font-size: 0.8rem; } }
      @media (min-width: 992px) {
        #main-menu.navbar .navbar-nav > li > a {
          padding: 0 15px;
          font-size: 0.85rem; } }
      @media (min-width: 1200px) {
        #main-menu.navbar .navbar-nav > li > a {
          padding: 0 15px;
          font-size: 0.9rem; } }
      #main-menu.navbar .navbar-nav > li > a.dropdown-toggle::after {
        display: none; }
    #main-menu.navbar .navbar-nav > li:first-child > a {
      border-left: 1px solid transparent; }
    #main-menu.navbar .navbar-nav > li.active > a {
      border: none !important; }
      #main-menu.navbar .navbar-nav > li.active > a:hover, #main-menu.navbar .navbar-nav > li.active > a:focus {
        text-decoration: underline; }
    #main-menu.navbar .navbar-nav > li > .dropdown-menu {
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      border: none;
      border-radius: 0;
      margin: 0;
      font-size: 0.9rem;
      width: 15rem; }
      #main-menu.navbar .navbar-nav > li > .dropdown-menu > a,
      #main-menu.navbar .navbar-nav > li > .dropdown-menu > .dropdown-item {
        white-space: normal;
        padding: 0.5rem 1.25rem;
        line-height: 1.4; }
    #main-menu.navbar .navbar-nav > li:nth-child(7) > .dropdown-menu {
      left: auto;
      right: 0; }
  #main-menu.navbar.color-black {
    background-color: #000;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }
    #main-menu.navbar.color-black .navbar-nav > li > a {
      border-color: rgba(255, 255, 255, 0.14);
      color: #fff; }
    #main-menu.navbar.color-black .navbar-nav > li:hover > a,
    #main-menu.navbar.color-black .navbar-nav > li:focus > a,
    #main-menu.navbar.color-black .navbar-nav > li > a:hover,
    #main-menu.navbar.color-black .navbar-nav > li > a:focus {
      background-color: #eeeeee;
      color: #000; }
    #main-menu.navbar.color-black .navbar-nav > li.active > a {
      background-color: #222222;
      color: #fff; }
    #main-menu.navbar.color-black .navbar-nav > li.active:hover > a,
    #main-menu.navbar.color-black .navbar-nav > li.active:focus > a,
    #main-menu.navbar.color-black .navbar-nav > li > a:hover,
    #main-menu.navbar.color-black .navbar-nav > li > a:focus {
      background-color: #eeeeee;
      color: #000; }
    #main-menu.navbar.color-black .navbar-nav > li > .dropdown-menu {
      background-color: #eeeeee;
      color: #000; }
      #main-menu.navbar.color-black .navbar-nav > li > .dropdown-menu > a {
        background-color: #eeeeee;
        color: #000; }
        #main-menu.navbar.color-black .navbar-nav > li > .dropdown-menu > a:hover, #main-menu.navbar.color-black .navbar-nav > li > .dropdown-menu > a:focus {
          background-color: #cccccc;
          color: #000; }
        #main-menu.navbar.color-black .navbar-nav > li > .dropdown-menu > a.active {
          background-color: #e1e1e1;
          color: #000; }
    #main-menu.navbar.color-black .navbar-nav > li .dropdown-divider {
      border-top: 1px solid rgba(0, 0, 0, 0.11); }
  #main-menu.navbar.color-gray {
    background-color: #eeeeee;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }
    #main-menu.navbar.color-gray .navbar-nav > li > a {
      border-color: rgba(0, 0, 0, 0.09);
      color: #000; }
    #main-menu.navbar.color-gray .navbar-nav > li:hover > a,
    #main-menu.navbar.color-gray .navbar-nav > li:focus > a,
    #main-menu.navbar.color-gray .navbar-nav > li > a:hover,
    #main-menu.navbar.color-gray .navbar-nav > li > a:focus {
      background-color: #fff;
      color: #000; }
    #main-menu.navbar.color-gray .navbar-nav > li.active > a {
      background-color: #e1e1e1;
      color: #000; }
    #main-menu.navbar.color-gray .navbar-nav > li.active:hover > a,
    #main-menu.navbar.color-gray .navbar-nav > li.active:focus > a,
    #main-menu.navbar.color-gray .navbar-nav > li > a:hover,
    #main-menu.navbar.color-gray .navbar-nav > li > a:focus {
      background-color: #fff;
      color: #000; }
    #main-menu.navbar.color-gray .navbar-nav > li > .dropdown-menu {
      background-color: #fff;
      color: #000; }
      #main-menu.navbar.color-gray .navbar-nav > li > .dropdown-menu > a {
        background-color: #fff;
        color: #000; }
        #main-menu.navbar.color-gray .navbar-nav > li > .dropdown-menu > a:hover, #main-menu.navbar.color-gray .navbar-nav > li > .dropdown-menu > a:focus {
          background-color: #eeeeee;
          color: #000; }
        #main-menu.navbar.color-gray .navbar-nav > li > .dropdown-menu > a.active {
          background-color: #f9f9f9;
          color: #000; }
    #main-menu.navbar.color-gray .navbar-nav > li .dropdown-divider {
      border-top: 1px solid rgba(0, 0, 0, 0.11); }
  #main-menu.navbar.color-white {
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); }
    #main-menu.navbar.color-white .navbar-nav > li > a {
      border-color: rgba(0, 0, 0, 0.09);
      color: #333333; }
    #main-menu.navbar.color-white .navbar-nav > li:hover > a,
    #main-menu.navbar.color-white .navbar-nav > li:focus > a,
    #main-menu.navbar.color-white .navbar-nav > li > a:hover,
    #main-menu.navbar.color-white .navbar-nav > li > a:focus {
      background-color: #5d1725;
      color: #fff; }
    #main-menu.navbar.color-white .navbar-nav > li.active > a {
      background-color: #5d1725;
      color: #fff; }
    #main-menu.navbar.color-white .navbar-nav > li.active:hover > a,
    #main-menu.navbar.color-white .navbar-nav > li.active:focus > a,
    #main-menu.navbar.color-white .navbar-nav > li > a:hover,
    #main-menu.navbar.color-white .navbar-nav > li > a:focus {
      background-color: #5d1725;
      color: #fff; }
    #main-menu.navbar.color-white .navbar-nav > li > .dropdown-menu {
      background-color: #5d1725;
      color: #fff; }
      #main-menu.navbar.color-white .navbar-nav > li > .dropdown-menu > a {
        background-color: #5d1725;
        color: #fff; }
        #main-menu.navbar.color-white .navbar-nav > li > .dropdown-menu > a:hover, #main-menu.navbar.color-white .navbar-nav > li > .dropdown-menu > a:focus {
          background-color: #40101a;
          color: #fff; }
        #main-menu.navbar.color-white .navbar-nav > li > .dropdown-menu > a.active {
          background-color: #4f131f;
          color: #fff; }
    #main-menu.navbar.color-white .navbar-nav > li .dropdown-divider {
      border-top: 1px solid rgba(0, 0, 0, 0.11); }

.hero, .main-breadcrumb, .page {
  position: relative;
  z-index: 1; }

.section-menu {
  position: relative;
  z-index: 0;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #eeeeee;
  overflow: hidden;
  height: 70px; }
  .section-menu.affix {
    position: fixed;
    z-index: 1029;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    -webkit-box-shadow: 0 0.25rem 3rem rgba(0, 0, 0, 0.09);
    box-shadow: 0 0.25rem 3rem rgba(0, 0, 0, 0.09); }
  .section-menu > a.scroll {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 70px;
    height: 100%;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #aaaaaa;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s; }
    .section-menu > a.scroll.scroll-left {
      right: auto;
      left: 0;
      background: -webkit-gradient(linear, left top, right top, color-stop(50%, #eeeeee), to(rgba(238, 238, 238, 0)));
      background: -webkit-linear-gradient(left, #eeeeee 50%, rgba(238, 238, 238, 0) 100%);
      background: -o-linear-gradient(left, #eeeeee 50%, rgba(238, 238, 238, 0) 100%);
      background: linear-gradient(to right, #eeeeee 50%, rgba(238, 238, 238, 0) 100%); }
    .section-menu > a.scroll.scroll-right {
      right: 0;
      left: auto;
      background: -webkit-gradient(linear, right top, left top, color-stop(50%, #eeeeee), to(rgba(238, 238, 238, 0)));
      background: -webkit-linear-gradient(right, #eeeeee 50%, rgba(238, 238, 238, 0) 100%);
      background: -o-linear-gradient(right, #eeeeee 50%, rgba(238, 238, 238, 0) 100%);
      background: linear-gradient(to left, #eeeeee 50%, rgba(238, 238, 238, 0) 100%); }
    .section-menu > a.scroll:hover, .section-menu > a.scroll:focus {
      color: #000; }
  .section-menu .nav {
    position: relative;
    z-index: 1;
    overflow-x: scroll;
    height: 100%;
    padding-bottom: 17px;
    opacity: 0;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s; }
    .section-menu .nav > li {
      position: relative;
      width: auto; }
      .section-menu .nav > li:first-child {
        padding-left: 300px; }
      .section-menu .nav > li:last-child {
        padding-right: 300px; }
      .section-menu .nav > li > a {
        position: relative;
        z-index: 1001;
        padding: 0 20px;
        height: 70px;
        font-size: .85rem;
        line-height: 70px;
        text-decoration: none;
        text-transform: uppercase;
        white-space: nowrap;
        color: #222222;
        border-left: 1px solid #f9f9f9;
        border-right: 1px solid #e1e1e1;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s; }
        .section-menu .nav > li > a.active {
          text-decoration: underline;
          color: #5d1725;
          background-color: #f9f9f9;
          border-color: #f9f9f9; }
        .section-menu .nav > li > a:hover, .section-menu .nav > li > a:focus {
          text-decoration: underline;
          background-color: #fff; }
      .section-menu .nav > li > .dropdown-menu {
        -webkit-box-shadow: 0 0.25rem 3rem rgba(0, 0, 0, 0.09);
        box-shadow: 0 0.25rem 3rem rgba(0, 0, 0, 0.09);
        border: none;
        border-radius: 0;
        margin: 0;
        font-size: 0.85rem;
        width: 15rem; }
        .section-menu .nav > li > .dropdown-menu > a,
        .section-menu .nav > li > .dropdown-menu > .dropdown-item {
          white-space: normal;
          padding: 0.5rem 1.25rem;
          line-height: 1.4; }
          @media (max-width: 575.98px) {
            .section-menu .nav > li > .dropdown-menu > a,
            .section-menu .nav > li > .dropdown-menu > .dropdown-item {
              padding: 0.4rem 1.25rem;
              line-height: 1.2; } }
  .section-menu.dropdown-open {
    overflow: visible;
    z-index: 2; }
    .section-menu.dropdown-open .nav {
      overflow: visible;
      padding-bottom: 0; }

@media (max-width: 767.98px) {
  .msu-sidebar {
    margin-top: 5.25rem;
    margin-bottom: 2.25rem; } }

.msu-sidebar .nav li a {
  display: block;
  padding: 1rem 1rem;
  background-color: transparent;
  color: #333333;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  border-left: 1px solid #e1e1e1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s; }
  .msu-sidebar .nav li a:hover, .msu-sidebar .nav li a:focus {
    background-color: transparent;
    border-left: 1px solid #7a1e30;
    color: #7a1e30; }

.msu-sidebar .nav li.active {
  background-color: #4f131f; }
  .msu-sidebar .nav li.active > a {
    background-color: #6b1b2b;
    color: #fff;
    border-left: 1px solid #4f131f;
    border-bottom: 3px solid #40101a;
    text-decoration: none; }
    .msu-sidebar .nav li.active > a:hover, .msu-sidebar .nav li.active > a:focus {
      background-color: #7a1e30;
      color: #fff; }

.msu-sidebar .nav li.dropdown .dropdown-menu {
  position: relative;
  top: auto;
  left: auto;
  z-index: inherit;
  float: none;
  min-width: 0;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  border-radius: 0; }
  .msu-sidebar .nav li.dropdown .dropdown-menu {
    margin: 1.5rem 0; }
  .msu-sidebar .nav li.dropdown .dropdown-menu a {
    display: block;
    padding: 0.65rem 6%;
    background-color: #4f131f;
    color: #fff;
    font-size: 0.85rem;
    font-weight: 400;
    text-decoration: none;
    white-space: normal;
    border-left: 1px solid #4f131f; }
    .msu-sidebar .nav li.dropdown .dropdown-menu a:hover, .msu-sidebar .nav li.dropdown .dropdown-menu a:focus {
      background-color: #320c14;
      color: #fff; }
    .msu-sidebar .nav li.dropdown .dropdown-menu a.active {
      background-color: #40101a;
      color: #fff; }

.msu-sidebar .nav li.dropdown.active .dropdown-menu {
  display: block; }

.msu-sidebar .nav li.dropdown:not(.active) .dropdown-menu {
  display: none; }

.hero {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 800px;
  background-color: #000;
  color: #fff; }
  @media (max-width: 575.98px) {
    .hero {
      min-height: 640px;
      height: auto !important; } }
  .hero .item {
    position: relative;
    display: block;
    height: 800px;
    width: 100%;
    padding: 0;
    margin: 0;
    color: #fff;
    text-decoration: none;
    z-index: 0;
    background-size: cover;
    background-position: center center; }
    @media (max-width: 575.98px) {
      .hero .item,
      .hero .item .row-wrapper {
        min-height: 640px;
        height: auto !important; } }
    .hero .item .container-fluid,
    .hero .item .container {
      position: relative;
      z-index: 5; }
    .hero .item p a:not(.btn) {
      color: #fff; }
  .hero.hero-thumb {
    height: auto;
    margin-top: -75px;
    margin-bottom: 2rem;
    z-index: 10;
    background: transparent;
    color: inherit;
    overflow: visible; }

.directory-bio .nav-tabs .nav-item {
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 600; }
  .directory-bio .nav-tabs .nav-item .nav-link {
    border: 1px solid transparent;
    border-top-width: 2px; }
    .directory-bio .nav-tabs .nav-item .nav-link:focus, .directory-bio .nav-tabs .nav-item .nav-link:hover {
      border-color: #eeeeee #eeeeee #e1e1e1; }
  .directory-bio .nav-tabs .nav-item.show .nav-link,
  .directory-bio .nav-tabs .nav-item .nav-link.active {
    border-color: #7a1e30 #e1e1e1 #fff;
    color: #000;
    background: #fff; }

.directory-bio .card-expertise .btn {
  font-size: 0.75rem; }

.dash {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  background: #f9f9f9; }

.title-logo {
  color: #000;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s; }
  .title-logo:focus, .title-logo:hover {
    color: #7a1e30;
    text-decoration: none; }

.dash-nav .hamburger,
.dash-toolbar .hamburger {
  margin-right: -15px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease; }
  .dash-nav .hamburger.is-open .hamburger-box .hamburger-inner, .dash-nav .hamburger.is-open .hamburger-box .hamburger-inner::before, .dash-nav .hamburger.is-open .hamburger-box .hamburger-inner::after,
  .dash-toolbar .hamburger.is-open .hamburger-box .hamburger-inner,
  .dash-toolbar .hamburger.is-open .hamburger-box .hamburger-inner::before,
  .dash-toolbar .hamburger.is-open .hamburger-box .hamburger-inner::after {
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease; }
  .dash-nav .hamburger.is-open .hamburger-box .hamburger-inner::before,
  .dash-toolbar .hamburger.is-open .hamburger-box .hamburger-inner::before {
    margin-left: 11.5px;
    width: 11.5px; }
  .dash-nav .hamburger.is-open .hamburger-box .hamburger-inner::after,
  .dash-toolbar .hamburger.is-open .hamburger-box .hamburger-inner::after {
    margin-left: 5.75px;
    width: 17.25px; }
  .dash-nav .hamburger.is-open:hover .hamburger-box .hamburger-inner::before,
  .dash-toolbar .hamburger.is-open:hover .hamburger-box .hamburger-inner::before {
    margin-left: 0;
    width: 23px; }
  .dash-nav .hamburger.is-open:hover .hamburger-box .hamburger-inner::after,
  .dash-toolbar .hamburger.is-open:hover .hamburger-box .hamburger-inner::after {
    margin-left: 0;
    width: 23px; }
  .dash-nav .hamburger.is-closed .hamburger-box .hamburger-inner, .dash-nav .hamburger.is-closed .hamburger-box .hamburger-inner::before, .dash-nav .hamburger.is-closed .hamburger-box .hamburger-inner::after,
  .dash-toolbar .hamburger.is-closed .hamburger-box .hamburger-inner,
  .dash-toolbar .hamburger.is-closed .hamburger-box .hamburger-inner::before,
  .dash-toolbar .hamburger.is-closed .hamburger-box .hamburger-inner::after {
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease; }
  .dash-nav .hamburger.is-closed .hamburger-box .hamburger-inner::before,
  .dash-toolbar .hamburger.is-closed .hamburger-box .hamburger-inner::before {
    margin-right: 11.5px;
    width: 11.5px; }
  .dash-nav .hamburger.is-closed .hamburger-box .hamburger-inner::after,
  .dash-toolbar .hamburger.is-closed .hamburger-box .hamburger-inner::after {
    margin-right: 5.75px;
    width: 17.25px; }
  .dash-nav .hamburger.is-closed:hover .hamburger-box .hamburger-inner::before,
  .dash-toolbar .hamburger.is-closed:hover .hamburger-box .hamburger-inner::before {
    margin-right: 0;
    width: 23px; }
  .dash-nav .hamburger.is-closed:hover .hamburger-box .hamburger-inner::after,
  .dash-toolbar .hamburger.is-closed:hover .hamburger-box .hamburger-inner::after {
    margin-right: 0;
    width: 23px; }

.dash-nav {
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  z-index: 1031;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 0.5rem 5rem rgba(0, 0, 0, 0.11);
  box-shadow: 0 0.5rem 5rem rgba(0, 0, 0, 0.11);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease; }
  @media (min-width: 768px) {
    .dash-nav {
      max-width: 280px; } }
  .dash-nav header {
    height: 70px;
    width: 100%;
    margin: 0;
    padding: 15px 25px;
    background-color: #fff;
    border-left: 3px solid #4f131f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease; }
    @media (min-width: 768px) {
      .dash-nav header {
        height: 100px; } }
  .dash-nav .dash-nav-inner {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow-y: auto; }
  .dash-nav .nav li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px 25px;
    color: #777777;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease; }
    .dash-nav .nav li a:hover, .dash-nav .nav li a:focus {
      color: #000;
      background-color: #f9f9f9; }
    .dash-nav .nav li a.active {
      color: #7a1e30;
      background-color: #f9f9f9; }
    .dash-nav .nav li a > .icon:first-child {
      width: 30px;
      margin-right: 8px;
      text-align: center;
      -webkit-transition: all .3s ease;
      -o-transition: all .3s ease;
      transition: all .3s ease; }
    .dash-nav .nav li a > .label {
      -webkit-transition: all .3s ease;
      -o-transition: all .3s ease;
      transition: all .3s ease; }
    .dash-nav .nav li a > .badge {
      margin-left: 8px;
      -webkit-transition: all .3s ease;
      -o-transition: all .3s ease;
      transition: all .3s ease; }
    .dash-nav .nav li a > .icon:last-child {
      margin-left: 8px;
      opacity: .5;
      font-size: .5rem;
      -webkit-transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1);
      -o-transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1);
      transition: all 0.3s cubic-bezier(0.25, 0.25, 0.665, 1); }
    .dash-nav .nav li a[data-toggle="collapse"]:not(.collapsed) > .icon:last-child {
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg); }
  .dash-nav .nav li > .collapse {
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease; }
  .dash-nav .nav .nav li a {
    font-size: 0.875rem;
    padding: 4px 63px; }
  .dash-nav .navbar-divider {
    margin-left: 25px;
    margin-right: 25px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease; }
  .dash-nav .navbar-heading {
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 15px;
    text-transform: uppercase;
    color: #555555;
    font-weight: 300;
    letter-spacing: 1px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease; }
  .dash-nav.is-closed {
    max-width: 0; }
    @media (min-width: 768px) {
      .dash-nav.is-closed {
        max-width: 70px; } }
    .dash-nav.is-closed header {
      border-left: none;
      padding-left: 8.5px;
      padding-right: 8.5px; }
      .dash-nav.is-closed header .title-logo {
        width: 0;
        opacity: 0;
        overflow: hidden; }
      .dash-nav.is-closed header .hamburger {
        margin-right: 0; }
    .dash-nav.is-closed .nav li a {
      padding-left: 0;
      padding-right: 0; }
      .dash-nav.is-closed .nav li a > .icon:first-child {
        width: 70px;
        margin-right: 0; }
      .dash-nav.is-closed .nav li a > .label,
      .dash-nav.is-closed .nav li a > .badge,
      .dash-nav.is-closed .nav li a > .icon:last-child {
        margin: 0;
        padding: 0;
        width: 0;
        opacity: 0;
        overflow: hidden; }
    .dash-nav.is-closed .nav li > .collapse {
      height: 0;
      width: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
      overflow: hidden; }
    .dash-nav.is-closed .navbar-heading {
      height: 0;
      width: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
      overflow: hidden; }

.dash-nav + .dash-app {
  margin-left: 0; }
  @media (min-width: 768px) {
    .dash-nav + .dash-app {
      margin-left: 280px; } }

.dash-nav.is-closed + .dash-app {
  margin-left: 0; }
  @media (min-width: 768px) {
    .dash-nav.is-closed + .dash-app {
      margin-left: 70px; } }

.dash-app {
  width: 100%;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease; }

.dash-nav + .dash-app .dash-toolbar {
  margin-left: 0; }
  @media (min-width: 768px) {
    .dash-nav + .dash-app .dash-toolbar {
      margin-left: 280px; } }

.dash-nav.is-closed + .dash-app .dash-toolbar {
  margin-left: 0; }
  @media (min-width: 768px) {
    .dash-nav.is-closed + .dash-app .dash-toolbar {
      margin-left: 70px; } }

.dash-toolbar {
  position: fixed;
  z-index: 1030;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 70px;
  padding: 15px 25px;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #fff;
  -webkit-box-shadow: 0 0.5rem 5rem rgba(0, 0, 0, 0.11);
  box-shadow: 0 0.5rem 5rem rgba(0, 0, 0, 0.11);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease; }
  .dash-toolbar .toolbar-actions .nav li,
  .dash-toolbar .toolbar-account .nav li {
    margin-right: 8px; }
    .dash-toolbar .toolbar-actions .nav li:last-child,
    .dash-toolbar .toolbar-account .nav li:last-child {
      margin-right: 0; }
    .dash-toolbar .toolbar-actions .nav li a.nav-link,
    .dash-toolbar .toolbar-account .nav li a.nav-link {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 0.25rem;
      color: #555555;
      -webkit-transition: all .3s ease;
      -o-transition: all .3s ease;
      transition: all .3s ease; }
    .dash-toolbar .toolbar-actions .nav li a:hover, .dash-toolbar .toolbar-actions .nav li a:focus,
    .dash-toolbar .toolbar-account .nav li a:hover,
    .dash-toolbar .toolbar-account .nav li a:focus {
      color: #000;
      background-color: #f9f9f9; }
    .dash-toolbar .toolbar-actions .nav li a.active,
    .dash-toolbar .toolbar-account .nav li a.active {
      color: #7a1e30;
      background-color: #f9f9f9; }
    .dash-toolbar .toolbar-actions .nav li a.dropdown-toggle:after,
    .dash-toolbar .toolbar-account .nav li a.dropdown-toggle:after {
      margin-left: 8px;
      vertical-align: initial;
      border: solid #777777;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 2px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
  .dash-toolbar .toolbar-actions .dropdown-menu,
  .dash-toolbar .toolbar-account .dropdown-menu {
    -webkit-box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.07);
    box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.07);
    border: 0; }
    .dash-toolbar .toolbar-actions .dropdown-menu .dropdown-header,
    .dash-toolbar .toolbar-account .dropdown-menu .dropdown-header {
      margin: 0;
      text-transform: uppercase;
      color: #555555;
      font-weight: 300;
      font-size: 0.75rem;
      letter-spacing: 1px;
      -webkit-transition: all .3s ease;
      -o-transition: all .3s ease;
      transition: all .3s ease; }
  .dash-toolbar .toolbar-account .nav li a {
    position: relative; }
    .dash-toolbar .toolbar-account .nav li a.nav-link {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      padding: 2px 0 0;
      margin: 0;
      font-size: 1.3rem;
      line-height: 1;
      border-radius: 50%; }
      @media (max-width: 1200px) {
        .dash-toolbar .toolbar-account .nav li a.nav-link {
          font-size: calc(1.255rem + 0.06vw) ; } }
      .dash-toolbar .toolbar-account .nav li a.nav-link:hover, .dash-toolbar .toolbar-account .nav li a.nav-link:focus {
        color: #7a1e30;
        background-color: rgba(150, 37, 60, 0.05); }
    .dash-toolbar .toolbar-account .nav li a.dropdown-toggle:after {
      display: none; }
    .dash-toolbar .toolbar-account .nav li a.indicator-new-item {
      color: #7a1e30; }
      .dash-toolbar .toolbar-account .nav li a.indicator-new-item:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: auto;
        left: auto;
        right: auto;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #7a1e30; }
  @media (max-width: 767.98px) {
    .dash-toolbar .toolbar-account .dropdown-menu .dropdown-item {
      width: 230px;
      white-space: normal; } }
  .dash-toolbar .toolbar-account .dropdown-menu.dropdown-alt-fixed-width .dropdown-item {
    width: 230px;
    padding: .5rem 1.5rem;
    border: none;
    white-space: normal;
    line-height: 1.3125; }
    @media (min-width: 576px) {
      .dash-toolbar .toolbar-account .dropdown-menu.dropdown-alt-fixed-width .dropdown-item {
        width: 280px; } }
  .dash-toolbar .toolbar-account .dropdown-menu.dropdown-alt-notifications .dropdown-item.indicator-new-item:before {
    top: .8rem;
    bottom: auto;
    left: .5rem;
    right: auto; }
  .dash-toolbar .toolbar-account .dropdown-menu.dropdown-alt-notifications .dropdown-item p {
    margin-bottom: 0; }
    .dash-toolbar .toolbar-account .dropdown-menu.dropdown-alt-notifications .dropdown-item p.time {
      font-weight: 300;
      font-size: 80%; }

.dash-nav .nav li a.btn-blue,
.dash-nav .toolbar-actions .nav li a.btn-blue,
.dash-nav .toolbar-account .nav li a.btn-blue,
.dash-toolbar .nav li a.btn-blue,
.dash-toolbar .toolbar-actions .nav li a.btn-blue,
.dash-toolbar .toolbar-account .nav li a.btn-blue {
  color: #007da4;
  background-color: rgba(0, 125, 164, 0.07); }

.dash-nav .nav li a.btn-indigo,
.dash-nav .toolbar-actions .nav li a.btn-indigo,
.dash-nav .toolbar-account .nav li a.btn-indigo,
.dash-toolbar .nav li a.btn-indigo,
.dash-toolbar .toolbar-actions .nav li a.btn-indigo,
.dash-toolbar .toolbar-account .nav li a.btn-indigo {
  color: #6610f2;
  background-color: rgba(102, 16, 242, 0.07); }

.dash-nav .nav li a.btn-purple,
.dash-nav .toolbar-actions .nav li a.btn-purple,
.dash-nav .toolbar-account .nav li a.btn-purple,
.dash-toolbar .nav li a.btn-purple,
.dash-toolbar .toolbar-actions .nav li a.btn-purple,
.dash-toolbar .toolbar-account .nav li a.btn-purple {
  color: #642f6c;
  background-color: rgba(100, 47, 108, 0.07); }

.dash-nav .nav li a.btn-pink,
.dash-nav .toolbar-actions .nav li a.btn-pink,
.dash-nav .toolbar-account .nav li a.btn-pink,
.dash-toolbar .nav li a.btn-pink,
.dash-toolbar .toolbar-actions .nav li a.btn-pink,
.dash-toolbar .toolbar-account .nav li a.btn-pink {
  color: #e83e8c;
  background-color: rgba(232, 62, 140, 0.07); }

.dash-nav .nav li a.btn-red,
.dash-nav .toolbar-actions .nav li a.btn-red,
.dash-nav .toolbar-account .nav li a.btn-red,
.dash-toolbar .nav li a.btn-red,
.dash-toolbar .toolbar-actions .nav li a.btn-red,
.dash-toolbar .toolbar-account .nav li a.btn-red {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.07); }

.dash-nav .nav li a.btn-orange,
.dash-nav .toolbar-actions .nav li a.btn-orange,
.dash-nav .toolbar-account .nav li a.btn-orange,
.dash-toolbar .nav li a.btn-orange,
.dash-toolbar .toolbar-actions .nav li a.btn-orange,
.dash-toolbar .toolbar-account .nav li a.btn-orange {
  color: #a9431e;
  background-color: rgba(169, 67, 30, 0.07); }

.dash-nav .nav li a.btn-yellow,
.dash-nav .toolbar-actions .nav li a.btn-yellow,
.dash-nav .toolbar-account .nav li a.btn-yellow,
.dash-toolbar .nav li a.btn-yellow,
.dash-toolbar .toolbar-actions .nav li a.btn-yellow,
.dash-toolbar .toolbar-account .nav li a.btn-yellow {
  color: #ffb81c;
  background-color: rgba(255, 184, 28, 0.07); }

.dash-nav .nav li a.btn-green,
.dash-nav .toolbar-actions .nav li a.btn-green,
.dash-nav .toolbar-account .nav li a.btn-green,
.dash-toolbar .nav li a.btn-green,
.dash-toolbar .toolbar-actions .nav li a.btn-green,
.dash-toolbar .toolbar-account .nav li a.btn-green {
  color: #8f993e;
  background-color: rgba(143, 153, 62, 0.07); }

.dash-nav .nav li a.btn-teal,
.dash-nav .toolbar-actions .nav li a.btn-teal,
.dash-nav .toolbar-account .nav li a.btn-teal,
.dash-toolbar .nav li a.btn-teal,
.dash-toolbar .toolbar-actions .nav li a.btn-teal,
.dash-toolbar .toolbar-account .nav li a.btn-teal {
  color: #20c997;
  background-color: rgba(32, 201, 151, 0.07); }

.dash-nav .nav li a.btn-cyan,
.dash-nav .toolbar-actions .nav li a.btn-cyan,
.dash-nav .toolbar-account .nav li a.btn-cyan,
.dash-toolbar .nav li a.btn-cyan,
.dash-toolbar .toolbar-actions .nav li a.btn-cyan,
.dash-toolbar .toolbar-account .nav li a.btn-cyan {
  color: #00aead;
  background-color: rgba(0, 174, 173, 0.07); }

.dash-nav .nav li a.btn-white,
.dash-nav .toolbar-actions .nav li a.btn-white,
.dash-nav .toolbar-account .nav li a.btn-white,
.dash-toolbar .nav li a.btn-white,
.dash-toolbar .toolbar-actions .nav li a.btn-white,
.dash-toolbar .toolbar-account .nav li a.btn-white {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.07); }

.dash-nav .nav li a.btn-gray,
.dash-nav .toolbar-actions .nav li a.btn-gray,
.dash-nav .toolbar-account .nav li a.btn-gray,
.dash-toolbar .nav li a.btn-gray,
.dash-toolbar .toolbar-actions .nav li a.btn-gray,
.dash-toolbar .toolbar-account .nav li a.btn-gray {
  color: #777777;
  background-color: rgba(119, 119, 119, 0.07); }

.dash-nav .nav li a.btn-gray-dark,
.dash-nav .toolbar-actions .nav li a.btn-gray-dark,
.dash-nav .toolbar-account .nav li a.btn-gray-dark,
.dash-toolbar .nav li a.btn-gray-dark,
.dash-toolbar .toolbar-actions .nav li a.btn-gray-dark,
.dash-toolbar .toolbar-account .nav li a.btn-gray-dark {
  color: #333333;
  background-color: rgba(51, 51, 51, 0.07); }

.dash-nav .nav li a.btn-sky,
.dash-nav .toolbar-actions .nav li a.btn-sky,
.dash-nav .toolbar-account .nav li a.btn-sky,
.dash-toolbar .nav li a.btn-sky,
.dash-toolbar .toolbar-actions .nav li a.btn-sky,
.dash-toolbar .toolbar-account .nav li a.btn-sky {
  color: #bdd6e6;
  background-color: rgba(189, 214, 230, 0.07); }

.dash-nav .nav li a.btn-lime,
.dash-nav .toolbar-actions .nav li a.btn-lime,
.dash-nav .toolbar-account .nav li a.btn-lime,
.dash-toolbar .nav li a.btn-lime,
.dash-toolbar .toolbar-actions .nav li a.btn-lime,
.dash-toolbar .toolbar-account .nav li a.btn-lime {
  color: #b7bf10;
  background-color: rgba(183, 191, 16, 0.07); }

.dash-nav .nav li a.btn-gold,
.dash-nav .toolbar-actions .nav li a.btn-gold,
.dash-nav .toolbar-account .nav li a.btn-gold,
.dash-toolbar .nav li a.btn-gold,
.dash-toolbar .toolbar-actions .nav li a.btn-gold,
.dash-toolbar .toolbar-account .nav li a.btn-gold {
  color: #c99700;
  background-color: rgba(201, 151, 0, 0.07); }

.dash-nav .nav li a.btn-taupe,
.dash-nav .toolbar-actions .nav li a.btn-taupe,
.dash-nav .toolbar-account .nav li a.btn-taupe,
.dash-toolbar .nav li a.btn-taupe,
.dash-toolbar .toolbar-actions .nav li a.btn-taupe,
.dash-toolbar .toolbar-account .nav li a.btn-taupe {
  color: #a69f88;
  background-color: rgba(166, 159, 136, 0.07); }

.dash-nav .nav li a.btn-sand,
.dash-nav .toolbar-actions .nav li a.btn-sand,
.dash-nav .toolbar-account .nav li a.btn-sand,
.dash-toolbar .nav li a.btn-sand,
.dash-toolbar .toolbar-actions .nav li a.btn-sand,
.dash-toolbar .toolbar-account .nav li a.btn-sand {
  color: #dac79d;
  background-color: rgba(218, 199, 157, 0.07); }

.dash-nav .nav li a.btn-soil,
.dash-nav .toolbar-actions .nav li a.btn-soil,
.dash-nav .toolbar-account .nav li a.btn-soil,
.dash-toolbar .nav li a.btn-soil,
.dash-toolbar .toolbar-actions .nav li a.btn-soil,
.dash-toolbar .toolbar-account .nav li a.btn-soil {
  color: #6e4c1e;
  background-color: rgba(110, 76, 30, 0.07); }

.dash-nav .nav li a.btn-maroon,
.dash-nav .toolbar-actions .nav li a.btn-maroon,
.dash-nav .toolbar-account .nav li a.btn-maroon,
.dash-toolbar .nav li a.btn-maroon,
.dash-toolbar .toolbar-actions .nav li a.btn-maroon,
.dash-toolbar .toolbar-account .nav li a.btn-maroon {
  color: #5d1725;
  background-color: rgba(93, 23, 37, 0.07); }

.dash-nav .nav li a.btn-maroon-light,
.dash-nav .toolbar-actions .nav li a.btn-maroon-light,
.dash-nav .toolbar-account .nav li a.btn-maroon-light,
.dash-toolbar .nav li a.btn-maroon-light,
.dash-toolbar .toolbar-actions .nav li a.btn-maroon-light,
.dash-toolbar .toolbar-account .nav li a.btn-maroon-light {
  color: #7a1e30;
  background-color: rgba(122, 30, 48, 0.07); }

.dash-nav .nav li a.btn-black,
.dash-nav .toolbar-actions .nav li a.btn-black,
.dash-nav .toolbar-account .nav li a.btn-black,
.dash-toolbar .nav li a.btn-black,
.dash-toolbar .toolbar-actions .nav li a.btn-black,
.dash-toolbar .toolbar-account .nav li a.btn-black {
  color: #000;
  background-color: rgba(0, 0, 0, 0.07); }

.dash-nav .nav li a.btn-primary,
.dash-nav .toolbar-actions .nav li a.btn-primary,
.dash-nav .toolbar-account .nav li a.btn-primary,
.dash-toolbar .nav li a.btn-primary,
.dash-toolbar .toolbar-actions .nav li a.btn-primary,
.dash-toolbar .toolbar-account .nav li a.btn-primary {
  color: #7a1e30;
  background-color: rgba(122, 30, 48, 0.07); }

.dash-nav .nav li a.btn-secondary,
.dash-nav .toolbar-actions .nav li a.btn-secondary,
.dash-nav .toolbar-account .nav li a.btn-secondary,
.dash-toolbar .nav li a.btn-secondary,
.dash-toolbar .toolbar-actions .nav li a.btn-secondary,
.dash-toolbar .toolbar-account .nav li a.btn-secondary {
  color: #555555;
  background-color: rgba(85, 85, 85, 0.07); }

.dash-nav .nav li a.btn-success,
.dash-nav .toolbar-actions .nav li a.btn-success,
.dash-nav .toolbar-account .nav li a.btn-success,
.dash-toolbar .nav li a.btn-success,
.dash-toolbar .toolbar-actions .nav li a.btn-success,
.dash-toolbar .toolbar-account .nav li a.btn-success {
  color: #8f993e;
  background-color: rgba(143, 153, 62, 0.07); }

.dash-nav .nav li a.btn-info,
.dash-nav .toolbar-actions .nav li a.btn-info,
.dash-nav .toolbar-account .nav li a.btn-info,
.dash-toolbar .nav li a.btn-info,
.dash-toolbar .toolbar-actions .nav li a.btn-info,
.dash-toolbar .toolbar-account .nav li a.btn-info {
  color: #00aead;
  background-color: rgba(0, 174, 173, 0.07); }

.dash-nav .nav li a.btn-warning,
.dash-nav .toolbar-actions .nav li a.btn-warning,
.dash-nav .toolbar-account .nav li a.btn-warning,
.dash-toolbar .nav li a.btn-warning,
.dash-toolbar .toolbar-actions .nav li a.btn-warning,
.dash-toolbar .toolbar-account .nav li a.btn-warning {
  color: #ffb81c;
  background-color: rgba(255, 184, 28, 0.07); }

.dash-nav .nav li a.btn-danger,
.dash-nav .toolbar-actions .nav li a.btn-danger,
.dash-nav .toolbar-account .nav li a.btn-danger,
.dash-toolbar .nav li a.btn-danger,
.dash-toolbar .toolbar-actions .nav li a.btn-danger,
.dash-toolbar .toolbar-account .nav li a.btn-danger {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.07); }

.dash-nav .nav li a.btn-light,
.dash-nav .toolbar-actions .nav li a.btn-light,
.dash-nav .toolbar-account .nav li a.btn-light,
.dash-toolbar .nav li a.btn-light,
.dash-toolbar .toolbar-actions .nav li a.btn-light,
.dash-toolbar .toolbar-account .nav li a.btn-light {
  color: #f9f9f9;
  background-color: rgba(249, 249, 249, 0.07); }

.dash-nav .nav li a.btn-dark,
.dash-nav .toolbar-actions .nav li a.btn-dark,
.dash-nav .toolbar-account .nav li a.btn-dark,
.dash-toolbar .nav li a.btn-dark,
.dash-toolbar .toolbar-actions .nav li a.btn-dark,
.dash-toolbar .toolbar-account .nav li a.btn-dark {
  color: #333333;
  background-color: rgba(51, 51, 51, 0.07); }

.dash-toolbar + .dash-content {
  margin-top: 100px; }

.dash-content header {
  margin-top: 2rem;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e1e1e1; }

.dash-content .page-title {
  margin: 0; }
  .dash-content .page-title small {
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 300; }

.dash-content .card {
  -webkit-box-shadow: 0 0.25rem 3rem rgba(0, 0, 0, 0.09);
  box-shadow: 0 0.25rem 3rem rgba(0, 0, 0, 0.09);
  border: 0; }
  .dash-content .card .card-header {
    font-weight: 600; }

.dash-content .dataTables_wrapper .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.dash-content .dataTables_wrapper table.dataTable {
  margin: 0 !important; }

.dash-content .dataTables_wrapper .dataTables_length,
.dash-content .dataTables_wrapper .dataTables_filter {
  font-size: 0.85rem;
  padding: 1rem; }
  .dash-content .dataTables_wrapper .dataTables_length label,
  .dash-content .dataTables_wrapper .dataTables_filter label {
    margin-bottom: 0; }

.dash-content .dataTables_wrapper .dataTables_info,
.dash-content .dataTables_wrapper .dataTables_paginate {
  font-size: 0.85rem;
  padding: 1rem; }
  .dash-content .dataTables_wrapper .dataTables_info label,
  .dash-content .dataTables_wrapper .dataTables_paginate label {
    margin-bottom: 0; }

.social-icon {
  width: 28px;
  height: 28px;
  display: block;
  padding: 0 6px;
  line-height: 28px;
  color: #fff !important; }
  .social-icon.facebook {
    background-color: #3B5998; }
  .social-icon.google-plus {
    background-color: #DC4E41; }
  .social-icon.instagram {
    background-color: #E4405F; }
  .social-icon.linkedin {
    background-color: #0077B5; }
  .social-icon.pinterest {
    background-color: #BD081C; }
  .social-icon.tumblr {
    background-color: #36465D; }
  .social-icon.twitter {
    background-color: #1DA1F2; }
  .social-icon.vine {
    background-color: #11B48A; }
  .social-icon.youtube {
    background-color: #CD201F; }
  .social-icon.rss {
    background-color: #FFA500; }
  .social-icon.vimeo {
    background-color: #1AB7EA; }
  .social-icon.snapchat {
    background-color: #FFFC00; }
  .social-icon.skype {
    background-color: #00AFF0; }

.social-icon-container .nav-social {
  height: 28px;
  list-style: none;
  margin: 0;
  padding: 0; }
  .social-icon-container .nav-social li {
    margin: 0;
    padding: 0; }

.social-icon-container.social-maroon .social-icon {
  background-color: #5d1725 !important;
  color: #fff !important; }
  .social-icon-container.social-maroon .social-icon:hover, .social-icon-container.social-maroon .social-icon:focus {
    background-color: #40101a !important;
    color: #fff !important; }

#social-footer {
  border-bottom: 4px solid #320c14;
  max-height: 60px;
  overflow: hidden;
  background-color: #5d1725; }
  #social-footer .nav-social {
    padding: 0;
    height: 56px; }
  #social-footer .social-icon {
    width: 40px;
    height: 56px;
    font-size: 1.2rem;
    line-height: 56px;
    padding: 0 10px;
    text-align: center; }
    @media (min-width: 768px) {
      #social-footer .social-icon {
        width: 50px;
        padding: 0 15px; } }

.bg-container,
.bg-img,
.bg-pattern,
.bg-color,
.bg-fade,
.bg-video,
.fg-link {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0; }

.bg-img, .bg-video {
  z-index: 0; }

.bg-pattern {
  z-index: 1; }

.bg-color {
  z-index: 2; }

.bg-fade {
  z-index: 3; }

.fg-link {
  z-index: 10; }

.bg-img, .bg-video {
  background: transparent;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.bg-pattern {
  background: transparent;
  background-repeat: repeat; }

.bg-video {
  position: relative;
  overflow: hidden; }
  .bg-video video {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.horz-black-transparent {
  background: -webkit-gradient(linear, left top, right top, from(#000), to(rgba(0, 0, 0, 0))) repeat-x !important;
  background: -webkit-linear-gradient(left, #000, rgba(0, 0, 0, 0)) repeat-x !important;
  background: -o-linear-gradient(left, #000, rgba(0, 0, 0, 0)) repeat-x !important;
  background: linear-gradient(to right, #000, rgba(0, 0, 0, 0)) repeat-x !important; }

.horz-transparent-black {
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), to(#000)) repeat-x !important;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), #000) repeat-x !important;
  background: -o-linear-gradient(left, rgba(0, 0, 0, 0), #000) repeat-x !important;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #000) repeat-x !important; }

.vert-black-transparent-black {
  background: -webkit-gradient(linear, left top, left bottom, from(#000), color-stop(rgba(0, 0, 0, 0)), to(#000)) repeat-x !important;
  background: -webkit-linear-gradient(top, #000, rgba(0, 0, 0, 0), #000) repeat-x !important;
  background: -o-linear-gradient(top, #000, rgba(0, 0, 0, 0), #000) repeat-x !important;
  background: linear-gradient(to bottom, #000, rgba(0, 0, 0, 0), #000) repeat-x !important; }

.vert-transparent-black {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)) repeat-x !important;
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), #000) repeat-x !important;
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0), #000) repeat-x !important;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000) repeat-x !important; }

.vert-black-transparent {
  background: -webkit-gradient(linear, left top, left bottom, from(#000), to(rgba(0, 0, 0, 0))) repeat-x !important;
  background: -webkit-linear-gradient(top, #000, rgba(0, 0, 0, 0)) repeat-x !important;
  background: -o-linear-gradient(top, #000, rgba(0, 0, 0, 0)) repeat-x !important;
  background: linear-gradient(to bottom, #000, rgba(0, 0, 0, 0)) repeat-x !important; }

.horz-white-transparent {
  background: -webkit-gradient(linear, left top, right top, from(#fff), to(rgba(255, 255, 255, 0))) repeat-x !important;
  background: -webkit-linear-gradient(left, #fff, rgba(255, 255, 255, 0)) repeat-x !important;
  background: -o-linear-gradient(left, #fff, rgba(255, 255, 255, 0)) repeat-x !important;
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0)) repeat-x !important; }

.horz-transparent-white {
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#fff)) repeat-x !important;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #fff) repeat-x !important;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0), #fff) repeat-x !important;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff) repeat-x !important; }

.vert-white-transparent {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.75)), to(rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0) 100%); }

.vert-black40-black70 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 100%); }

.horz-maroon-transparent {
  background: -webkit-gradient(linear, left top, right top, from(rgba(93, 23, 37, 0.5)), color-stop(25%, rgba(93, 23, 37, 0.4)), color-stop(75%, rgba(93, 23, 37, 0)), to(rgba(93, 23, 37, 0)));
  background: -webkit-linear-gradient(left, rgba(93, 23, 37, 0.5) 0%, rgba(93, 23, 37, 0.4) 25%, rgba(93, 23, 37, 0) 75%, rgba(93, 23, 37, 0) 100%);
  background: -o-linear-gradient(left, rgba(93, 23, 37, 0.5) 0%, rgba(93, 23, 37, 0.4) 25%, rgba(93, 23, 37, 0) 75%, rgba(93, 23, 37, 0) 100%);
  background: linear-gradient(to right, rgba(93, 23, 37, 0.5) 0%, rgba(93, 23, 37, 0.4) 25%, rgba(93, 23, 37, 0) 75%, rgba(93, 23, 37, 0) 100%); }

.horz-maroon-black {
  background: -webkit-gradient(linear, left top, right top, from(rgba(93, 23, 37, 0.85)), to(rgba(0, 0, 0, 0.85)));
  background: -webkit-linear-gradient(left, rgba(93, 23, 37, 0.85) 0%, rgba(0, 0, 0, 0.85) 100%);
  background: -o-linear-gradient(left, rgba(93, 23, 37, 0.85) 0%, rgba(0, 0, 0, 0.85) 100%);
  background: linear-gradient(to right, rgba(93, 23, 37, 0.85) 0%, rgba(0, 0, 0, 0.85) 100%); }

.horz-gray100-gray300 {
  background: -webkit-gradient(linear, left top, right top, from(#f9f9f9), to(#e1e1e1)) repeat-x !important;
  background: -webkit-linear-gradient(left, #f9f9f9, #e1e1e1) repeat-x !important;
  background: -o-linear-gradient(left, #f9f9f9, #e1e1e1) repeat-x !important;
  background: linear-gradient(to right, #f9f9f9, #e1e1e1) repeat-x !important; }

.horz-gray800-black {
  background: -webkit-gradient(linear, left top, right top, from(#333333), to(#000)) repeat-x !important;
  background: -webkit-linear-gradient(left, #333333, #000) repeat-x !important;
  background: -o-linear-gradient(left, #333333, #000) repeat-x !important;
  background: linear-gradient(to right, #333333, #000) repeat-x !important; }

.vert-gray100-gray300 {
  background: -webkit-gradient(linear, left top, left bottom, from(#f9f9f9), to(#e1e1e1)) repeat-x !important;
  background: -webkit-linear-gradient(top, #f9f9f9, #e1e1e1) repeat-x !important;
  background: -o-linear-gradient(top, #f9f9f9, #e1e1e1) repeat-x !important;
  background: linear-gradient(to bottom, #f9f9f9, #e1e1e1) repeat-x !important; }

.vert-gray800-black {
  background: -webkit-gradient(linear, left top, left bottom, from(#333333), to(#000)) repeat-x !important;
  background: -webkit-linear-gradient(top, #333333, #000) repeat-x !important;
  background: -o-linear-gradient(top, #333333, #000) repeat-x !important;
  background: linear-gradient(to bottom, #333333, #000) repeat-x !important; }

.horz-ltmaroon-dkmaroon {
  background: -webkit-gradient(linear, left top, right top, from(#882236), to(#40101a));
  background: -webkit-linear-gradient(left, #882236 0%, #40101a 100%);
  background: -o-linear-gradient(left, #882236 0%, #40101a 100%);
  background: linear-gradient(to right, #882236 0%, #40101a 100%); }

.bg-maroon-primary {
  background-color: #5d1725 !important; }

a.bg-maroon-primary:hover, a.bg-maroon-primary:focus,
button.bg-maroon-primary:hover,
button.bg-maroon-primary:focus {
  background-color: #340d15 !important; }

.bg-maroon-100 {
  background-color: #96253c !important; }

a.bg-maroon-100:hover, a.bg-maroon-100:focus,
button.bg-maroon-100:hover,
button.bg-maroon-100:focus {
  background-color: #6d1b2c !important; }

.bg-maroon-200 {
  background-color: #882236 !important; }

a.bg-maroon-200:hover, a.bg-maroon-200:focus,
button.bg-maroon-200:hover,
button.bg-maroon-200:focus {
  background-color: #5f1826 !important; }

.bg-maroon-300 {
  background-color: #7a1e30 !important; }

a.bg-maroon-300:hover, a.bg-maroon-300:focus,
button.bg-maroon-300:hover,
button.bg-maroon-300:focus {
  background-color: #511420 !important; }

.bg-maroon-400 {
  background-color: #6b1b2b !important; }

a.bg-maroon-400:hover, a.bg-maroon-400:focus,
button.bg-maroon-400:hover,
button.bg-maroon-400:focus {
  background-color: #42101a !important; }

.bg-maroon-500 {
  background-color: #5d1725 !important; }

a.bg-maroon-500:hover, a.bg-maroon-500:focus,
button.bg-maroon-500:hover,
button.bg-maroon-500:focus {
  background-color: #340d15 !important; }

.bg-maroon-600 {
  background-color: #4f131f !important; }

a.bg-maroon-600:hover, a.bg-maroon-600:focus,
button.bg-maroon-600:hover,
button.bg-maroon-600:focus {
  background-color: #26090f !important; }

.bg-maroon-700 {
  background-color: #40101a !important; }

a.bg-maroon-700:hover, a.bg-maroon-700:focus,
button.bg-maroon-700:hover,
button.bg-maroon-700:focus {
  background-color: #170609 !important; }

.bg-maroon-800 {
  background-color: #320c14 !important; }

a.bg-maroon-800:hover, a.bg-maroon-800:focus,
button.bg-maroon-800:hover,
button.bg-maroon-800:focus {
  background-color: #090204 !important; }

.bg-maroon-900 {
  background-color: #24090e !important; }

a.bg-maroon-900:hover, a.bg-maroon-900:focus,
button.bg-maroon-900:hover,
button.bg-maroon-900:focus {
  background-color: black !important; }

.bg-gray-100 {
  background-color: #f9f9f9 !important; }

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #e0e0e0 !important; }

.bg-gray-200 {
  background-color: #eeeeee !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #d5d5d5 !important; }

.bg-gray-300 {
  background-color: #e1e1e1 !important; }

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c8c8c8 !important; }

.bg-gray-400 {
  background-color: #cccccc !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #b3b3b3 !important; }

.bg-gray-500 {
  background-color: #aaaaaa !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #919191 !important; }

.bg-gray-600 {
  background-color: #777777 !important; }

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #5e5e5e !important; }

.bg-gray-700 {
  background-color: #555555 !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #3c3c3c !important; }

.bg-gray-800 {
  background-color: #333333 !important; }

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #1a1a1a !important; }

.bg-gray-900 {
  background-color: #222222 !important; }

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #090909 !important; }

.bg-blue {
  background-color: #007da4 !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #005671 !important; }

.bg-indigo {
  background-color: #6610f2 !important; }

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important; }

.bg-purple {
  background-color: #642f6c !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #432048 !important; }

.bg-pink {
  background-color: #e83e8c !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important; }

.bg-red {
  background-color: #dc3545 !important; }

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #bd2130 !important; }

.bg-orange {
  background-color: #a9431e !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #7e3216 !important; }

.bg-yellow {
  background-color: #ffb81c !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #e89f00 !important; }

.bg-green {
  background-color: #8f993e !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #6d752f !important; }

.bg-teal {
  background-color: #20c997 !important; }

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important; }

.bg-cyan {
  background-color: #00aead !important; }

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #007b7a !important; }

.bg-white {
  background-color: #fff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-gray {
  background-color: #777777 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #5e5e5e !important; }

.bg-gray-dark {
  background-color: #333333 !important; }

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #1a1a1a !important; }

.bg-sky {
  background-color: #bdd6e6 !important; }

a.bg-sky:hover, a.bg-sky:focus,
button.bg-sky:hover,
button.bg-sky:focus {
  background-color: #98bfd8 !important; }

.bg-lime {
  background-color: #b7bf10 !important; }

a.bg-lime:hover, a.bg-lime:focus,
button.bg-lime:hover,
button.bg-lime:focus {
  background-color: #8a900c !important; }

.bg-gold {
  background-color: #c99700 !important; }

a.bg-gold:hover, a.bg-gold:focus,
button.bg-gold:hover,
button.bg-gold:focus {
  background-color: #967100 !important; }

.bg-taupe {
  background-color: #a69f88 !important; }

a.bg-taupe:hover, a.bg-taupe:focus,
button.bg-taupe:hover,
button.bg-taupe:focus {
  background-color: #90876b !important; }

.bg-sand {
  background-color: #dac79d !important; }

a.bg-sand:hover, a.bg-sand:focus,
button.bg-sand:hover,
button.bg-sand:focus {
  background-color: #ccb278 !important; }

.bg-soil {
  background-color: #6e4c1e !important; }

a.bg-soil:hover, a.bg-soil:focus,
button.bg-soil:hover,
button.bg-soil:focus {
  background-color: #463013 !important; }

.bg-maroon {
  background-color: #5d1725 !important; }

a.bg-maroon:hover, a.bg-maroon:focus,
button.bg-maroon:hover,
button.bg-maroon:focus {
  background-color: #340d15 !important; }

.bg-maroon-light {
  background-color: #7a1e30 !important; }

a.bg-maroon-light:hover, a.bg-maroon-light:focus,
button.bg-maroon-light:hover,
button.bg-maroon-light:focus {
  background-color: #511420 !important; }

.bg-black {
  background-color: #000 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important; }

.blend-color {
  mix-blend-mode: color; }

.opacity-0 {
  opacity: 0; }

.opacity-10 {
  opacity: 0.1; }

.opacity-20 {
  opacity: 0.2; }

.opacity-30 {
  opacity: 0.3; }

.opacity-40 {
  opacity: 0.4; }

.opacity-50 {
  opacity: 0.5; }

.opacity-60 {
  opacity: 0.6; }

.opacity-70 {
  opacity: 0.7; }

.opacity-80 {
  opacity: 0.8; }

.opacity-90 {
  opacity: 0.9; }

.opacity-100 {
  opacity: 1; }

.flag--test:not(.no-frame) {
  position: relative;
  border: 1rem solid #e83e8c !important; }
  .flag--test:not(.no-frame):before {
    content: "This is under development!" !important;
    position: relative !important;
    display: block !important;
    width: 100% !important;
    background-color: #e83e8c !important;
    color: #fff !important;
    text-align: center !important;
    padding: 1rem 1rem 2rem !important;
    font-weight: bold !important;
    font-size: 2rem !important; }
  .flag--test:not(.no-frame).yellow {
    border: 1rem solid #ffb81c !important; }
    .flag--test:not(.no-frame).yellow:before {
      background-color: #ffb81c !important;
      color: #000 !important; }
  .flag--test:not(.no-frame).red {
    border: 1rem solid #dc3545 !important; }
    .flag--test:not(.no-frame).red:before {
      background-color: #dc3545 !important;
      color: #fff !important; }
  .flag--test:not(.no-frame).david {
    border: 1rem solid #ffb81c !important;
    border-top: 1rem solid #000 !important; }
    .flag--test:not(.no-frame).david:before {
      background-color: #ffb81c !important;
      background: -webkit-repeating-linear-gradient(45deg, #ffb81c, #ffb81c 100px, black 100px, black 200px);
      background: -o-repeating-linear-gradient(45deg, #ffb81c, #ffb81c 100px, black 100px, black 200px);
      background: repeating-linear-gradient(45deg, #ffb81c, #ffb81c 100px, black 100px, black 200px);
      color: #fff !important; }

.flag--test.quan {
  background-image: url(../../img/background/bg_dev.jpg) !important;
  background-repeat: repeat !important;
  background-size: 150px !important; }

.h-px-100 {
  height: 100px !important; }

.h-px-125 {
  height: 125px !important; }

.h-px-150 {
  height: 150px !important; }

.h-px-175 {
  height: 175px !important; }

.h-px-200 {
  height: 200px !important; }

.h-px-225 {
  height: 225px !important; }

.h-px-250 {
  height: 250px !important; }

.h-px-275 {
  height: 275px !important; }

.h-px-300 {
  height: 300px !important; }

.h-px-325 {
  height: 325px !important; }

.h-px-350 {
  height: 350px !important; }

.h-px-375 {
  height: 375px !important; }

.h-px-400 {
  height: 400px !important; }

.h-px-425 {
  height: 425px !important; }

.h-px-450 {
  height: 450px !important; }

.h-px-475 {
  height: 475px !important; }

.h-px-500 {
  height: 500px !important; }

.h-px-525 {
  height: 525px !important; }

.h-px-550 {
  height: 550px !important; }

.h-px-575 {
  height: 575px !important; }

.h-px-600 {
  height: 600px !important; }

.h-px-625 {
  height: 625px !important; }

.h-px-650 {
  height: 650px !important; }

.h-px-675 {
  height: 675px !important; }

.h-px-700 {
  height: 700px !important; }

.h-px-725 {
  height: 725px !important; }

.h-px-750 {
  height: 750px !important; }

.h-px-775 {
  height: 775px !important; }

.h-px-800 {
  height: 800px !important; }

.h-px-825 {
  height: 825px !important; }

.h-px-850 {
  height: 850px !important; }

.h-px-875 {
  height: 875px !important; }

.h-px-900 {
  height: 900px !important; }

.h-px-925 {
  height: 925px !important; }

.h-px-950 {
  height: 950px !important; }

.h-px-975 {
  height: 975px !important; }

.h-px-1000 {
  height: 1000px !important; }

.h-px-1025 {
  height: 1025px !important; }

.h-px-1050 {
  height: 1050px !important; }

.h-px-1075 {
  height: 1075px !important; }

.h-px-1100 {
  height: 1100px !important; }

.h-px-1125 {
  height: 1125px !important; }

.h-px-1150 {
  height: 1150px !important; }

.h-px-1175 {
  height: 1175px !important; }

.h-px-1200 {
  height: 1200px !important; }

.h-px-1225 {
  height: 1225px !important; }

.h-px-1250 {
  height: 1250px !important; }

.h-px-1275 {
  height: 1275px !important; }

.h-px-1300 {
  height: 1300px !important; }

.h-px-1325 {
  height: 1325px !important; }

.h-px-1350 {
  height: 1350px !important; }

.h-px-1375 {
  height: 1375px !important; }

.h-px-1400 {
  height: 1400px !important; }

.h-px-1425 {
  height: 1425px !important; }

.h-px-1450 {
  height: 1450px !important; }

.h-px-1475 {
  height: 1475px !important; }

.h-px-1500 {
  height: 1500px !important; }

.h-px-1525 {
  height: 1525px !important; }

.h-px-1550 {
  height: 1550px !important; }

.h-px-1575 {
  height: 1575px !important; }

.h-vh-10 {
  height: 10vh !important; }

.h-vh-20 {
  height: 20vh !important; }

.h-vh-30 {
  height: 30vh !important; }

.h-vh-40 {
  height: 40vh !important; }

.h-vh-50 {
  height: 50vh !important; }

.h-vh-60 {
  height: 60vh !important; }

.h-vh-70 {
  height: 70vh !important; }

.h-vh-80 {
  height: 80vh !important; }

.h-vh-90 {
  height: 90vh !important; }

.h-vh-100 {
  height: 100vh !important; }

.h-vh-110 {
  height: 110vh !important; }

.h-vh-120 {
  height: 120vh !important; }

.h-vh-130 {
  height: 130vh !important; }

.h-vh-140 {
  height: 140vh !important; }

.h-vh-150 {
  height: 150vh !important; }

.text-maroon-primary {
  color: #5d1725 !important; }

a.text-maroon-primary:hover, a.text-maroon-primary:focus {
  color: #20080d !important; }

.text-maroon-100 {
  color: #96253c !important; }

a.text-maroon-100:hover, a.text-maroon-100:focus {
  color: #591623 !important; }

.text-maroon-200 {
  color: #882236 !important; }

a.text-maroon-200:hover, a.text-maroon-200:focus {
  color: #4b121e !important; }

.text-maroon-300 {
  color: #7a1e30 !important; }

a.text-maroon-300:hover, a.text-maroon-300:focus {
  color: #3c0f18 !important; }

.text-maroon-400 {
  color: #6b1b2b !important; }

a.text-maroon-400:hover, a.text-maroon-400:focus {
  color: #2e0b12 !important; }

.text-maroon-500 {
  color: #5d1725 !important; }

a.text-maroon-500:hover, a.text-maroon-500:focus {
  color: #20080d !important; }

.text-maroon-600 {
  color: #4f131f !important; }

a.text-maroon-600:hover, a.text-maroon-600:focus {
  color: #110407 !important; }

.text-maroon-700 {
  color: #40101a !important; }

a.text-maroon-700:hover, a.text-maroon-700:focus {
  color: #030101 !important; }

.text-maroon-800 {
  color: #320c14 !important; }

a.text-maroon-800:hover, a.text-maroon-800:focus {
  color: black !important; }

.text-maroon-900 {
  color: #24090e !important; }

a.text-maroon-900:hover, a.text-maroon-900:focus {
  color: black !important; }

.text-gray-100 {
  color: #f9f9f9 !important; }

a.text-gray-100:hover, a.text-gray-100:focus {
  color: lightgray !important; }

.text-gray-200 {
  color: #eeeeee !important; }

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #c8c8c8 !important; }

.text-gray-300 {
  color: #e1e1e1 !important; }

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #bbbbbb !important; }

.text-gray-400 {
  color: #cccccc !important; }

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #a6a6a6 !important; }

.text-gray-500 {
  color: #aaaaaa !important; }

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #848484 !important; }

.text-gray-600 {
  color: #777777 !important; }

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #515151 !important; }

.text-gray-700 {
  color: #555555 !important; }

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #2f2f2f !important; }

.text-gray-800 {
  color: #333333 !important; }

a.text-gray-800:hover, a.text-gray-800:focus {
  color: #0d0d0d !important; }

.text-gray-900 {
  color: #222222 !important; }

a.text-gray-900:hover, a.text-gray-900:focus {
  color: black !important; }

.text-blue {
  color: #007da4 !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #004358 !important; }

.text-indigo {
  color: #6610f2 !important; }

a.text-indigo:hover, a.text-indigo:focus {
  color: #4709ac !important; }

.text-purple {
  color: #642f6c !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #331837 !important; }

.text-pink {
  color: #e83e8c !important; }

a.text-pink:hover, a.text-pink:focus {
  color: #c21766 !important; }

.text-red {
  color: #dc3545 !important; }

a.text-red:hover, a.text-red:focus {
  color: #a71d2a !important; }

.text-orange {
  color: #a9431e !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #682912 !important; }

.text-yellow {
  color: #ffb81c !important; }

a.text-yellow:hover, a.text-yellow:focus {
  color: #cf8e00 !important; }

.text-green {
  color: #8f993e !important; }

a.text-green:hover, a.text-green:focus {
  color: #5c6328 !important; }

.text-teal {
  color: #20c997 !important; }

a.text-teal:hover, a.text-teal:focus {
  color: #158765 !important; }

.text-cyan {
  color: #00aead !important; }

a.text-cyan:hover, a.text-cyan:focus {
  color: #006261 !important; }

.text-white {
  color: #fff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-gray {
  color: #777777 !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #515151 !important; }

.text-gray-dark {
  color: #333333 !important; }

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #0d0d0d !important; }

.text-sky {
  color: #bdd6e6 !important; }

a.text-sky:hover, a.text-sky:focus {
  color: #86b4d1 !important; }

.text-lime {
  color: #b7bf10 !important; }

a.text-lime:hover, a.text-lime:focus {
  color: #73780a !important; }

.text-gold {
  color: #c99700 !important; }

a.text-gold:hover, a.text-gold:focus {
  color: #7d5e00 !important; }

.text-taupe {
  color: #a69f88 !important; }

a.text-taupe:hover, a.text-taupe:focus {
  color: #817960 !important; }

.text-sand {
  color: #dac79d !important; }

a.text-sand:hover, a.text-sand:focus {
  color: #c5a765 !important; }

.text-soil {
  color: #6e4c1e !important; }

a.text-soil:hover, a.text-soil:focus {
  color: #32220e !important; }

.text-maroon {
  color: #5d1725 !important; }

a.text-maroon:hover, a.text-maroon:focus {
  color: #20080d !important; }

.text-maroon-light {
  color: #7a1e30 !important; }

a.text-maroon-light:hover, a.text-maroon-light:focus {
  color: #3c0f18 !important; }

.text-black {
  color: #000 !important; }

a.text-black:hover, a.text-black:focus {
  color: black !important; }

.font-size-60 {
  font-size: 60% !important; }

.font-size-65 {
  font-size: 65% !important; }

.font-size-70 {
  font-size: 70% !important; }

.font-size-75 {
  font-size: 75% !important; }

.font-size-80 {
  font-size: 80% !important; }

.font-size-85 {
  font-size: 85% !important; }

.font-size-90 {
  font-size: 90% !important; }

.font-size-95 {
  font-size: 95% !important; }

.font-size-100 {
  font-size: 100% !important; }

.font-size-105 {
  font-size: 105% !important; }

.font-size-110 {
  font-size: 110% !important; }

.font-size-115 {
  font-size: 115% !important; }

.font-size-120 {
  font-size: 120% !important; }

.text-shadow-sm {
  text-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.3) !important; }

.text-shadow {
  text-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.4) !important; }

.text-shadow-lg {
  text-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.5) !important; }

.text-shadow-none {
  text-shadow: none !important; }

@media print {
  #msu-header,
  #msu-footer,
  .hero,
  .breadcrumb,
  #mobile-menu,
  #main-menu,
  #user-menu,
  nav,
  .nav,
  .msu-sidebar,
  .sidebar-first,
  .sidebar-second,
  .page-tools,
  .browsehappy,
  .toolbar {
    display: none !important; } }
